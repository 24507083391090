// Variables
$bold-gray:#6e6e6e;
$silver-gray:#77788b;

@media print{

  body{
    margin: 0px;
  }

  //@page {
  //  size: A4;
  //  margin: 0;
  //}

  html, body {
    width: 210mm;
    height: 297mm;
    color: #333;
  }

  .navbar-default, .page-heading, .footer, .list{
    display: none !important;
    width: 0px !important;
  }

  .chartist-wraps{
    max-width: 100%;
    background-color: #fff;
  }

  .gray-bg{
    background-color: #ffffff !important;
    margin: 0px !important;
  }

  #page-wrapper{
    margin: 0px !important;
    //margin: 15pt 15pt 15pt 15pt !important;
    padding: 0 !important;
    height: auto;
    left: 0px !important;
    //background-color: #000 !important;
    //position: absolute !important;
  }

  #print-button {
    display: none;
  }

  .team-graph .chart-container{
    max-width: 100%;
  }

  .external-wraps{
    background-color: #ffffff !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  div#page-wrapper {
    background-color: #fff !important;
  }

  .below-chart {
    background-color: #f3f3f4 !important;
    margin-top: 25px;
  }

  .profile-section .chart-section{
    margin-top: 25px;
  }

  .theme-config-box{
    display: none !important;
  }

  .print-left{
    text-align: left !important;
  }

  .group-profile{
    .bottom-labels{
      .common-label:before{
        border-left: 80px solid transparent;
        border-right: 80px solid transparent;
      }
    }
  }

  .profile-data-panel{
    padding-left: 27px;
  }

  .team-report-page{
    clear: both;
    page-break-before: always;
    //background: white;
    //width: 21cm;
    //height: 29.7cm;
    //display: block;
    //margin: 0 auto;
    //margin-bottom: 0.5cm;
    //box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
  }

  // Team Report Styles
  .report-home-page .site-heading{
    color: #6e6e6e !important;
  }
  .report-home-page{
    padding-top:0px !important;
  }
  .ng-scope .ng-scope {
    border: 0 none !important;
  }
  .cf-team-right .content-top {
    min-height: inherit !important;
  }
  .cf-team-right .content-top.neutral {
    background-color: #f3f3f4 !important;
  }
  .cf-team-right .content-top.neutral h4, .cf-team-right .content-top.neutral p {
    color: #666 !important;
  }
  .cf-team-right .content-top:last-child {
    background: $clarify-color !important;
  }
  .cf-team-right .content-top:last-child h4, .cf-team-right .content-top:last-child p {
    color: #fff !important;
  }
  .cf-team.ideators .cf-team-right .content-top {
    background-color: #F78200 !important;
  }
  .cf-team.ideators .cf-team-right .content-top h4, .cf-team.ideators .cf-team-right .content-top p {
    color: #fff !important;
  }
  .cf-team.ideators .cf-team-right .content-top.neutral {
    background-color: #f3f3f4 !important;
  }
  .cf-team.ideators .cf-team-right .content-top.neutral h4, .cf-team.ideators .cf-team-right .content-top.neutral p {
    color: #666 !important;
  }
  .cf-team.developer .cf-team-right .content-top, .cf-team.developer .cf-team-right .content-top:last-child {
    background-color: #73BF39 !important;
  }
  .cf-team.developer .cf-team-right .content-top h4, .cf-team.developer .cf-team-right .content-top p,
  .cf-team.developer .cf-team-right .content-top:last-child h4, .cf-team.developer .cf-team-right .content-top:last-child p {
    color: #fff !important;
  }
  .cf-team.developer .cf-team-right .content-top.neutral{
    background-color: #f3f3f4 !important;
  }
  .cf-team.developer .cf-team-right .content-top.neutral h4, .cf-team.developer .cf-team-right .content-top.neutral p {
    color: #666 !important;
  }
  body *, body *:before, body *:after {
    color: $body-content-color !important;
  }
  .main .home-page-footer{
    background:#6E6E6E !important;
  }
  .four-pref.team-report-page h3.ng-binding {
    color: #fff !important;
  }

  .cf-team.implement .cf-team-right .content-top:last-child {
    background-color: $implement-color !important;
  }
  .cf-team.implement .cf-team-right .content-top:last-child h4, .cf-team.implement .cf-team-right .content-top:last-child p {
    color: #fff !important;
  }

  .cf-team.implement .cf-team-right .content-top.neutral {
    background-color: #f3f3f4 !important;
  }
  .cf-team.implement .cf-team-right .content-top.neutral h4, .cf-team.implement .cf-team-right .content-top.neutral p {
    color: #666 !important;
  }
  .cf-team.integrator .cf-team-right .content-top:last-child {
    background-color: transparent !important;
  }
  .btn-success {
    background-color: #06A6CE !important;
    border-color: #06A6CE !important;
    color: white !important;
  }
  .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
    background-color: #1a7bb9 !important;
    border-color: #1a7bb9 !important;
    color: white !important;
  }
  .four-sight .content-detail ul {
    padding: 0 0 0 16px;
    list-style-position: outside;
  }
  .team-report-page {
    padding-top: 0 !important;
  }
  .team-report-page h2.section-heading {
    margin-bottom: 7px;
    font-size: 30px;
  }
  .four-pref .col-content{
    min-height:780px !important;
  }
  .cf-team-left {
    font-size: 12px;
  }
  .performance h3 {
    margin-top: 0px;
    font-size: 17px;
  }
  .cf-team-left .border-box.lg {
    min-height: 370px !important;
  }
  .four-sight .content {
    padding: 15px 0px;
    min-height: inherit !important;
  }
  .communicate .my-table{
    margin-bottom:0px !important;
  }
  .put-all .feedback{
    margin-bottom:20px !important;
  }
  .feedback textarea {
    height: 143px !important;
  }
  .print-icon{
    display:none;
  }
  .cf-team.ideators .cf-team-left .border-box.lg{
    min-height:356px !important;
  }

  /* 2-Nov-2016 Profile Page */
  .chart-container.white-bg h2 {
    margin:0px !important;
  }

  #four-think, #four-box {
    page-break-after:always;
    padding-top:0px !important;
    padding-bottom:25px !important;
  }

  #four-profile .toolsguide-heading {
    padding-top:0px !important;
    padding-bottom:0px !important;
  }
  .toolsguide .toolsguide-heading .toolsguide-heading-text {
    margin-top: 0px !important;
    font-size: 30px !important;
    margin-bottom: 10px !important;
  }
  .toolsguide .toolsguide-heading h2 {
    margin: 0px !important;
  }
  .interpretive-wrapper .toolsguide {
    position:relative;
  }
  #four-think .box {
    width:48% !important;
    margin: 10px 1% !important;
    min-height: 225px !important;
  }
  #four-think .box.clarify , #four-think .box.ideate{
    min-height: 180px !important;
  }
  .toolsguide .toolsguide-content h3 {
    margin:0px 0px 5px !important;
    font-size: 16px !important;
  }
  #four-think .box h2 {
    margin:2px 0px !important;
    font-size: 16px !important;
  }
  #four-profile .toolsguide-content {
    padding-top:5px !important;
    padding-bottom:5px !important;
  }
  .toolsguide .box .box-top {
    padding:5px 15px !important;
  }

  .toolsguide .box .box-top img {
    max-width: 30px !important;
  }
  .chartist-wraps.ng-scope br {
    display: none !important;
  }

  #four-box .box {
    margin:10px 1% !important;
    width:48% !important;
    min-height: 480px !important;
  }

  #four-box .box.implement, #four-box .box.develop{
    min-height: 400px !important;
  }

  #four-box .box h2 {
    margin:10px 0px !important;
    font-size: 20px;
  }
  .below-chart-description .custom-footer {
    display: none !important;
  }
  p.profile-description.ng-binding {
    padding-top:15px;
  }
  .interpretive-wrapper .wrapper.wrapper-content.animated.fadeInRight {
    padding-top:0px !important;
  }
  .wrapper.wrapper-content.animated.fadeInRight {
    page-break-after: avoid;
    padding-bottom: 0;
  }
  .all-toolcards .toolcards.innovation-section + .innovation-section {
    page-break-before: always;
  }
  .all-toolcards .toolcards.innovation-section + .innovation-section .section-top{
    display: none;
  }
  .all-toolcards .innovation-section.toolcards p{
    margin-bottom: 25px !important;
  }
}

body.print-body #page-wrapper {
  margin: 0px;
}


/* Custom style for Print-mode of feedback-report*/
@media print{

  .feedback-slider {
    display: block !important;
    margin-top: 0px !important;
  }
  #nav, #Dots {
    display: none !important;
  }
  #feed-banner .title {
    color:#fff !important;
  }

  #feed-banner .title sup{
    color:#fff !important;
  }

  #great-mind .title-sm {
    color: #fff !important;
  }

  .feedback .custom-col {
    margin: 8px !important;
  }

  #f-now .title-sm {
    color:#fff !important;
  }

  .feedback {
    background-color:#f3f3f4 !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    position: relative;
    display: block;
    min-height: 450px;
    page-break-after: always;
    top: 0;
    bottom: 0;
    margin: 25px 0;
  }

  #owl-demo{
    max-width:794px !important;
  }

  #owl-demo .item{
    min-height: 515px !important;
  }

  .btn.minimalize-styl-2{
    display: none !important;
  }
  #f-implement1 .right-label-text span{
    color: #fff !important;
  }
  .profile-section .chart-section .visible-xs{
    display: none !important;
  }
  .profile-section .chart-section .hidden-xs{
    display: block !important;
  }
}



// Custom style for Print-mode of Team-report
@media print{
  .col-sm-6 {
    float:left;
    width: 50%;
  }
  .home-page {
    .site-heading {
      color: $bold-gray !important;
    }
    span.blue {
      color:$clarify-color !important;
    }
    &-content {
      .left p {
        color:$develop-color !important;
      }
    }
    &-footer {
      .content {
        background:  $bold-gray !important; 
        p {
          color:#fff !important;
          b {
            color:#fff !important;
          }
        }
      }
    }
  }
  // style-for-four-pref-page
  .col-sm-3.col {
    width:25%;
    float: left;
  }
  .clarifier .col-content {
      h3 {
        background-color:$clarify-color !important;
      }
    }
  .ideator .col-content {
    h3 {
      background-color:$ideate-color !important;
    }
  }
  .developer .col-content {
    h3 {
      background-color:$develop-color !important;
    }
  }
  .implementer .col-content{
    h3 {
      background-color: $implement-color !important;
    }
  }

  .section-heading {
    color: $bold-gray !important;
  }

  // style-for-section-Clarifiers and teams-section 
  .cf-team {
    padding-top:25px;
    .performance h2 {
      color:$clarify-color !important;
    }
  }
  .col-sm-3 {
    width:25%;
    float:left;
  }
  .col-sm-9 {
    width:75%;
    float: left;
  }
  .cf-team-left{
     .border-box:last-child h4 {
        color:$clarify-color !important;
        background-color:#fff !important;
        border-bottom: 2px solid $clarify-color !important;
    }
    h4 {
      background-color:$clarify-color !important;
      color:#fff !important;
    }
  }
  .cf-team-right .content-top {
    background-color:$clarify-color !important;
    h4, p {
      color:#fff !important;
    }
    &:last-child {
      background-color:$gray !important;
      h4,p {
        color:#000 !important;
      }
    }
  }
  .cf-team-left .ask {
      border: 2px solid $clarify-color !important;
      span {
        font-weight: bold !important;
      }   
     &:before {
      background:$clarify-color !important ;
      color: #fff !important;
     }
  }
  .content-top {
    min-height: 450px !important;
  }
  .section-heading {
    margin-bottom: 30px;
  }
 
// style-for-Ideators and teams-section
  .cf-team.ideators {
    .performance h2 {
      color:$ideate-color !important; 
    }
    .cf-team-left {
      .border-box:last-child h4 {
          color: $ideate-color !important;
          background-color: #fff !important;
          border-bottom: 2px solid $ideate-color !important;
      }
      .ask {
          border: 2px solid $ideate-color !important;  
         &:before {
          top: 10px !important;
          background:$ideate-color !important ;
         }
      }
      h4 {
        background-color: $ideate-color !important;
          border-bottom: 2px solid $ideate-color !important;
      }
    }
    .cf-team-right {
      .content-top {
        background-color:$gray !important;
        h4, p {
          color:#000 !important;
        }
        &:last-child {
        background-color:$ideate-color !important;
        }
      }
    }
  }

// style-for-Developers and teams-section
  .cf-team.developer {
    .performance h2 {
      color:$develop-color !important; 
    }
    .cf-team-left {
      .border-box:last-child h4 {
          color:$develop-color !important;
          background-color: #fff !important;
          border-bottom: 2px solid $develop-color !important;
         }
      .ask {
          border: 2px solid $develop-color !important;  
         &:before {
          top: 10px !important;
          background:$develop-color !important ;
         }
      }
      h4 {
        background-color: $develop-color !important;
          border-bottom: 2px solid $develop-color !important;
      }
    }
    .cf-team-right {
      .content-top {
        background-color:$gray !important;
        h4, p {
          color:#000 !important;
        }
        &:last-child {
        background-color:$gray !important;
        }
      }
    }
  }
  .ask .my-form label {
    width:42% !important;
  }
// style-for-Implementers and teams-section
  .cf-team.implement {
    .ask .my-form label {
        width:45% !important;
    }
    .ask .my-form input[type=checkbox] {
        margin: 4px 5px 0px 6px !important;
    }
    .performance h2 {
      color:$implement-color !important; 
    }
    .cf-team-left {
      .border-box:last-child h4 {
          color: $implement-color !important;
          background-color: #fff !important;
          border-bottom: 2px solid $implement-color !important;
      }
      .ask {
          border: 2px solid $implement-color !important;  
         &:before {
          top: 10px !important;
          background:$implement-color !important ;
         }
      }
      h4 {
        background-color: $implement-color !important;
          border-bottom: 2px solid $implement-color !important;
      }
    }
    .cf-team-right {
      .content-top {
        background-color:$implement-color !important;
        h4, p {
          color:#fff !important;
        }
        &:last-child {
          background-color:$gray !important;
          h4, p {
            color:#000 !important;
          }
        }
      }
    }
  }
// style-for-Integrators and teams-section
  .cf-team.integrator {
    .ask .my-form label {
        width:40% !important;
    }
    .ask .my-form input[type=checkbox] {
        margin: 4px 5px 0px 6px !important;
    }
    .performance h2 {
      color:$bold-gray !important; 
    }
    .cf-team-left {
      .border-box:last-child h4 {
          color: $bold-gray !important;
          background-color: #fff !important;
          border-bottom: 2px solid $bold-gray !important;
         }
      .ask {
          border: 2px solid $bold-gray !important;  
         &:before {
          top: 10px !important;
          background:$bold-gray !important ;
         }
      }
      h4 {
        background-color: $bold-gray !important;
          border-bottom: 2px solid $bold-gray !important;
      }
    }
    .cf-team-right {
      .content-top {
        background-color:$bold-gray !important;
        h4, p {
          color:#fff !important;
        }
        &:last-child {
          background-color:$gray !important;
          h4, p {
            color:#000 !important;
          }
        }
      }
    }
  }

// style-for-How to communicate table-page 
  .communicate {
    .my-table {
      margin-bottom: 160px;
      table {
        tr:first-child {
          color:#fff !important;
          th {
            border-right: 1px solid #fff !important;
            color:#fff !important;
          }
        }
        td:first-child {
          color:#fff !important;
        }
      }
    }
  }

// style-for-Our-team members
  .our-team {
    .test-table {
      min-height: 850px;
      table tr th {
        color:#fff !important;
      }
    }
  }


  //  Style-forThe FourSight process 

  .four-sight {
    .content {
      min-height: 250px;

      .content-detail ul li {
        color:$body-content-color !important;
      }
      &.clarify .content-detail h2 {
        color:$clarify-color !important;
      }

      &.ideate .content-detail h2 {
        color:$ideate-color !important;
      }

      &.develop .content-detail h2 {
        color:$develop-color !important;
      }

      &.implement .content-detail h2 {
        color:$implement-color !important;
      }
    }
  }
  // put-all section 
  .put-all .feedback table tr td:first-child {
    color:#fff !important;
  }
    a[href]:after {
      content: none !important;
    }

      //FAQ-section
  .faq {
    p {
      color: $body-content-color !important;
    }
  }

}