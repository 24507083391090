// Font
$font-stack: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$muso-100: 'Museo-100';
$muso-300: 'Museo-300';
$muso-300-i: 'Museo-300Italic';
$muso-500: 'Museo-regular';
$muso-700: 'Museo-700';
$muso-700-i: 'Museo-700Italic';
$muso-sans: 'MuseoSans-300';
$car-font: 'Marydale';
$car-big-font: 'Bangers', cursive;
$font-museo: 'Conv_museo-500-webfont';
$font-helvetica: "Helvetica", "open sans";
$MuseoSansCondensed-300: 'MuseoSansCondensed-300';

$fwBold: 700;

//Foursight
// $clarify-color: #06A6CE;
// $ideate-color: #FF8300;
// $develop-color: #7BBC43;
// $implement-color: #FF485C;
$clarify-color: rgba(0, 173, 208, 1.0);
$ideate-color: rgba(255, 121, 0, 1.0);
$develop-color: rgba(105, 190, 40, 1.0);
$implement-color: rgba(245, 67, 89, 1.0);
$low-clarify-color: rgba(0, 173, 208, 1.0);
$low-ideate-color: rgba(255, 121, 0, 1.0);
$low-develop-color: rgba(105, 190, 40, 1.0);
$low-implement-color: rgba(245, 67, 89, 1.0);
$profile-connector-color: #656668;
// Profile Neutral color
$integrator-color: #E3E3E3;
// Team Report
$report-integrator: #a7a7a7;
// Body text color in reports
$body-content-color: #676a6c;
// Learner Map
$gray-lm: rgba(0, 0, 0, 0.2);
$border-color: #aaa;

$color-neutral-dark: #6D6E71;
$color-neutral-dark-500: #6A6A6A;
$color-neutral-mid: #CCC;
$color-neutral-light: #F1F1F2;
$color-neutral-mid-old: #9E9E97;
$color-dark-green: #4cae4c;
$color-light-grey: #d9d9d9;
$color-red: #FF485C;

$color-grey-1: #E7EAEC;


$clrGray1: #f6f6f6;


$clrDarkGray1: #ddd;

$clrGrey: #eee;
$clrWhite: #fff;
$clrBlack: #000;
$clrWarning: #f8ac59;

$assets: 'https://assets.foursightonline.com/app';
$adminUpload: 'https://assets.foursightonline.com/admin-uploads/docs/';

$separator: "\//";

$active-class: #017ebc;

// anchor Tag
$anchor-color: #337ab7;

//Sidebar
$color-light-type: #fff;
// submenu background
$fs-dark-grey: #464646;
// Hover
$fs-active-light: #5f5f63;
$fs-sidenav-bg: #333333;
$fs-sidenav-hov-bg: #3E3E3F;
$fs-green: #69BE28;


$fn-fs-h1: 44px;
$fn-fs-h1-mobile: 38px;
$fn-lh-h1: 1.1;

$fn-fs-h2: 38px;
$fn-fs-h2-mobile: 32px;
$fn-lh-h2: 1.2;

$fn-fs-h3: 32px;
$fn-fs-h3-mobile: 24px;
$fn-lh-h3: 1.2;

$fn-fs-h4: 24px;
$fn-fs-h4-mobile: 20px;
$fn-lh-h4: 1.2;

$fn-fs-h5: 18px;
$fn-lh-h5: 1.2;

$fn-fs-h6: 16px;
$fn-lh-h6: 1.3;


$fn-title-subtext: 22px;
$fn-title-subtext-mobile: 18px;


$pageWidth: 1080px;
$pageWidthSmall: 820px;
$pageWidthFluid: 1200px;


$secPad: 60px;

$pageWidth: 1080px;
$pageWidthFluid: 1200px;

$desktop: "only screen and (max-width: 1600px)";
$mac: "only screen and (max-width: 1350px)";
$pc-up: "only screen and (min-width: 1200px)";
$pc: "only screen and (max-width: 1199px)";
$tablet-up: "only screen and (min-width: 992px)";
$tablet: "only screen and (max-width: 991px)";
$mobile-up: "only screen and (min-width: 768px)";
$mobile: "only screen and (max-width: 767px)";
$xl: "only screen and (max-width: 1440px)";
$xl-up: "only screen and (min-width: 1440px)";

img {
  max-width: 100%;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.dir-col {
    flex-direction: column;
}

.dir-col-rev {
  flex-direction: column-reverse;
}

.mh-full {
    min-height: 100%;
}

.mt-auto {
    margin-top: auto;
}

.bt-0 {
    border-top: 0;
}

.bb-0 {
    border-bottom: 0;
}


@mixin headingfont() {
  font-family: $muso-700 !important;
  font-weight: normal !important;
}

@mixin contentfont() {
  font-family: $muso-300 !important;
  font-weight: normal !important;
}

.fn-body {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    --hGap: 10px;
    font-family: $muso-700;
    color: $color-neutral-dark;
    margin-bottom: var(--hGap);
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  .h1-text {
    font-size: $fn-fs-h1;
    line-height: $fn-lh-h1;

    @media #{$mobile} {
      font-size: $fn-fs-h1-mobile;
    }
  }

  h2,
  .h2-text {
    font-size: $fn-fs-h2;
    line-height: $fn-lh-h2;

    @media #{$mobile} {
      font-size: $fn-fs-h2-mobile;
    }
  }

  h3,
  .h3-text {
    font-size: $fn-fs-h3;
    line-height: $fn-lh-h3;

    @media #{$mobile} {
      font-size: $fn-fs-h3-mobile;
    }
  }

  h4,
  .h4-text {
    font-size: $fn-fs-h4;
    line-height: $fn-lh-h4;
  }

  h5,
  .h5-text {
    font-size: $fn-fs-h5;
    line-height: $fn-lh-h5;
  }

  h6,
  .h6-text {
    font-size: $fn-fs-h6;
    line-height: $fn-lh-h6;
  }

  $fn-title-subtext: 22px;
  $fn-title-subtext-mobile: 18px;

  p {
    font-size: 18px;
    line-height: 1.6;
    color: $color-neutral-dark-500;
    margin-top: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    @media #{$mobile} {
      font-size: 16px;
    }
  }

  ul,
  ol {
    padding: 0;
    margin: 0;
  }

  sup {
    font-size: 0.7em;
    position: relative;
    top: 5px;
    display: inline-block;
    vertical-align: top;
  }
}

.bg-grey {
  background-color: $clrGrey;
}

.fn-container {
  max-width: $pageWidth;
  width: calc(100% - 30px);
  margin-inline: auto;

  @media #{$tablet-up} {
    margin-left: 0;
  }

  &--fluid {
    max-width: $pageWidthFluid;
  }

  &--small {
    max-width: $pageWidthSmall;
    width: 100%;
  }
}

.text {

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }
}

.d {

  &-none {
    display: none;
  }
}

.section-title {
  text-align: center;

  &:not(:last-child) {
    margin-bottom: calc($secPad - 20px);
  }

  h2 {

    &:not(:last-child) {
      margin-block-end: calc($secPad / 3);
    }
  }

  p {
    max-width: 900px;
    width: 100%;
    margin-inline: auto;
    font-size: $fn-title-subtext;

    &:not(:last-child) {
      margin-block-end: calc($secPad / 3);
    }

    @media #{$mobile} {
      font-size: $fn-title-subtext-mobile;
    }
  }

  .section-title__linkcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;


    &:not(:last-child) {
      margin-block-end: 20px;
    }

    &>* {
      margin: 5px;
    }

    .fn-btn {
      padding-block: 6px;
      padding-inline: 13px;
      font-size: 12px;
      font-family: $muso-sans;
    }
  }
}

.section-padding {
  padding-block: $secPad;

  @media #{$mobile} {
    padding-block: ($secPad - 20px);
  }

  &-small {
    padding-block: calc($secPad / 2);
  }

  &-all {
    padding: $secPad;

    @media #{$mobile} {
      padding: calc($secPad / 2);
    }

    &-small {
      padding: calc($secPad / 2);
    }
  }
}

.headline {
  --lineSize: 10px;
  width: fit-content;
  padding-block-end: calc(10px + var(--lineSize));
  margin-inline: auto;
  position: relative;

  &--small-border {
    --lineSize: 6px;
  }

  &:not(:last-child) {
    margin-bottom: calc(var(--hGap) * 2.5);
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--lineSize);
    background: linear-gradient(90deg, $clarify-color 0%, $clarify-color 25%, $ideate-color 25%, $ideate-color 50%, $develop-color 50%, $develop-color 75%, $implement-color 75%, $implement-color 100%);
  }
}

.custom-list {
  li {
    font-size: 18px;
    line-height: 1.4;
    color: $color-neutral-dark-500;
    list-style: none;
    position: relative;
    padding-left: 15px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      width: 3px;
      height: 3px;
      background-color: var(--clrProfile);
    }
  }
}

.color-clarify {
  --clrProfile: #{$clarify-color};
}

.color-ideate {
  --clrProfile: #{$ideate-color};
}

.color-develop {
  --clrProfile: #{$develop-color};
}

.color-implement {
  --clrProfile: #{$implement-color};
}

.fn-btn {
  padding-block: 20px;
  padding-inline: 28px;
  background-color: $develop-color;
  color: $clrWhite;
  font-size: 18px;
  line-height: 1.2;
  display: inline-block;
  font-family: $muso-700;
  text-decoration: none;
  word-break: break-all;
  text-align: center;
  transition: background-color 0.3s;

  &:focus {
    outline: 1px dashed $color-neutral-dark-500;
    outline-offset: 4px;
  }

  &:hover {
    background-color: $color-neutral-dark;
    color: $clrWhite;
  }

  &--sm {
    font-size: 14px;
    padding-block: 10px;
    padding-inline: 20px;
  }

  &--grey {
    background-color: $color-neutral-light;
    color: $color-neutral-dark;
  }

  &--ongoing {
    background-color: $clrWarning;
    color: $clrWhite;

    i {
      animation: spin 0.3s infinite;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.color-clarify {
  --clrProfile: #{$clarify-color};
}

.color-ideate {
  --clrProfile: #{$ideate-color};
}

.color-develop {
  --clrProfile: #{$develop-color};
}

.color-implement {
  --clrProfile: #{$implement-color};
}

.w-4 {
  width: calc(100% / 3);
}

.w-3 {
  width: calc(100% / 4);

  &--wd {
    width: calc(100% / 3);
  }
}

.w-6 {
  width: calc(100% / 2);
}

.fn {
  &-row {
    --rGap: 15px;
    --rGap-minus: calc(-1 * var(--rGap));

    display: flex;
    flex-wrap: wrap;
    margin-inline: var(--rGap-minus);

    &--col-gap-exist {

      .fn-col {
        margin-bottom: calc($secPad / 2);
      }
    }
  }

  &-col {
    padding-inline: var(--rGap);
  }

  &-justify-center {
    justify-content: center
  }

  &-items-center {
    align-items: center
  }
}

.m {
  &x-auto {
    margin-inline: auto;
  }

  &l-auto {
    margin-inline-start: auto;
  }

  &l-0 {
    margin-inline-start: 0;
  }

  &r-auto {
    margin-inline-end: auto;
  }

  &r-0 {
    margin-inline-end: 0;
  }
}