
/*============================================================*/
/* Only demo */

$separator: "\//";

@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

@media (min-width: 769px){
  #page-wrapper {
    margin: 0 0 0 220px;
  }
}

#page-wrapper{
  min-height: 100vh;
  padding-bottom: 0 !important;
  display: flex;
  flex-direction: column;
}

.lightBoxGallery {
  text-align: center;
}

.lightBoxGallery img {
  margin: 5px;
}

.chat-element .media-body{
  width: auto;
}

.profile_types {
  //background-image: url("../assets/images/foursight-profile-types.png") !important;
  //background-image: url("../assets/images/foursight-profile-types.svg") !important;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 34px;
  height: 15px;
  margin-left: 6px;
  //background-size: 175px 46px;
}

.profile-2x{
  .profile_types{
    transform: scale(1.6);
  }
}

.accelerator {
  background-position: -35px 0 !important;
}

.analyst {
  background-position: 0 -32px !important;
}

.clarifier {
  background-position: -70px -32px !important;
}

.developer {
  background-position: -140px -16px !important;
}

.developer-bg{
  background-color: $develop-color !important;
}

.ideator-bg{
  background-color: $ideate-color !important;
}

.clarifier-bg{
  background-color: $clarify-color !important;
}

.implementer-bg{
  background-color: $implement-color !important;
}

.driver {
  background-position: -70px 0 !important;
}

.early-bird {
  background-position: -70px -16px !important;
}

.finisher {
  background-position: -140px 0 !important;
}

.hare {
  background-position: -105px -32px !important;
}

.idea-broker {
  background-position: -105px 0 !important;
}

.ideator {
  background-position: 0 -16px !important;
}

.implementer {
  background-position: -35px -16px !important;
}

.integrator {
  background-position: -140px -32px !important;
  margin-top: 5px
}

.optimist {
  background-position: -35px -32px !important;
}

.realist {
  background-position: -105px -16px !important;
}

.theorist {
  background-position: 0 0 !important;
}

.breadcrumb > li + li:before {
  //content: $separator;
}

/* Sort Arrows*/
.table-sortable {
  .st-sort-descent:before {
    content: '\25BC';
    position: absolute;
    right:10px;
  }

  .st-sort-ascent:before {
    content: '\25B2';
    position: absolute;
    right:10px;
  }

  th{
    position: relative;
  }
}

.checkbox-primary {
  margin: 0px;
}

.no-pad{
  padding: 0;
}

.pos-relative{
  position: relative;
}

.m-b-lg.pos-relative > .label{
  /*position: absolute;*/
  right: 10px;
  z-index: 9999;
  float: right;
}

/*Chart Styles*/
.chart-container, .below-chart, .below-chart-description{
  padding: 25px;
  max-width: 820px;
  width: 100%;
  /*margin: 0 auto;*/
}

.below-chart-description{
  background-color: $color-neutral-dark !important;
  color: #fff;
  padding-bottom: 0;

  .height30{
    height: 30px;
  }

  .custom-footer{
    margin-left: -25px;
    margin-right: -25px;
    background: #fff;
    color: $body-content-color;

    display: -webkit-flex;

    display: -ms-flexbox;

    display: flex;
    vertical-align: middle;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 0px;

    @media(max-width:767px){
      display: block;
      float: left;

      .col-xs-12{
        text-align: center !important;
        margin-bottom: 10px;
      }
    }

    p{
      margin: 0;
    }
  }
}

.team-profile-container{
  /*padding-left: 0;*/
}

.team-graph .chart-container {
  max-width: 650px;
}

.right-buttons{
  position: absolute;
  right: 10px;
  z-index: 9999;
}

.person-title {
  @media screen and (max-width: 1200px) {
    margin-top: 5px;
  }
  .m-b-sm{
    padding: 5px;
  }
  .img-responsive{
    max-width: 100%;
    width: 100%;
    display: inline-block;
    background: #f2f2f2;
    // height: 325px;
    // object-fit: cover;
  }
}

.profile-element{
  .img-circle{
    height: 38px;
    width: 38px;
    object-fit: cover;
  }
}

.edit-image-wrapper{
  position: relative;

  .remove-image{
    position: absolute;
    right: 5px;
    top: 5px;
    color: #fff;
  }
}

.profile-btn-wrapper{
  display: inline-block;
  margin-top: 5px;
  font-family: $font-stack;

  // @media(max-width:767px){
  //   display: block;
  //   clear: both;
  //   margin-top: 25px;
  // }

  @media(max-width:599px){
    display: block;
    clear: both;
    margin-top: 25px;
  }

  a{
    font-size: 12px;
  }
}

.m-about{
  /* margin-top: 50px;*/
  /*padding-right: 25%;*/

  h2{
    font-weight: bold;
    margin: 20px 0 5px 0;
  }
  .field_label, .field_detail{
    display: table-cell;
    word-wrap: break-word;
    //@include prefix(table-layout, fixed, webkit ms moz);

    @media(min-width:769px) and (max-width:1199px){
      display: block !important;
    }

    @media screen and (max-width: 599px) {
      width:100% !important;
      display: block !important;
      padding:0px !important;
    }
  }
  .field_label{
    width: 85px;
  }
  .field_detail{
    padding-left: 2px;
  }
  .list-group{
    .list-group-item{
      display: table;
      width:100%;
      border:none;
      padding: 3px 0px;
      font-size: 16px;

      @media screen and (max-width: 599px) {
        display: block;
      }
    }

    .list-group-item.col-sm-6{
      display: block;
      @media(min-width:768px){
        width: 50%;
      }
    }
  }
}

#print-button{
  a{
    font-size: 15px;
  }
}

.title-case{
  text-transform: capitalize;
}

.profile-layout th.sortable{
  text-align: left;
}

.below-chart {
  .left-label {
    background-color: $color-neutral-dark !important;
    float: left;
  }

  span{
    color: #fff !important;
    padding: 10px;
  }

  .right-label{
    float: right;
  }
}

.no-margin {
  margin: 0px;
}

.no-mg-top{
  margin-top: 0 !important;
}

.timezone-selector{
  max-width: 330px;
}

.titlename.no-mg-top{
  margin-top: 2px !important;
}

.m-about p{
  font-size: 14px;
}

.disabled.timezone-selector{
  outline: none;
  border: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  padding-top: 2px;
  padding-left: 0;

}

.disabled.timezone-selector::-ms-expand {
  display: none;
}

@media (max-width: 768px) {
  .body-small .navbar-static-side {
    display: none !important;
  }

  .body-small.mini-navbar .navbar-static-side {
    display: block !important;
  }

  .body-small.mini-navbar.full-screen .navbar-static-side {
    display: none !important;
  }

  .body-small.mini-navbar.full-screen #page-wrapper{
    margin-left: 0px !important;
  }
}

.color-red{
  color: #ed5565;
}

.pad-left-zero{
  padding-left: 0;
}

.ui-select-choices{
  .profile_types{
    float: left;
    margin-left: 0px;
    margin-right: 10px;
    margin-top: 11px;
  }
}

.date-wrapper:after{
  content: ' ]';
}

.date-wrapper:before{
  content: '[ ';
}

.light-border{
  border-bottom: 1px solid #e7eaec;
}

#toast-container>.toast-success{
  background-image: none !important;
}

#toast-container > .toast-success:before, #toast-container > .toast-error:before{
  content: '' !important;
}

.no-margin-fgroup{
  .form-group{
    margin-left: 0;
    margin-right: 0;
  }

  label{
    padding-left: 0;
  }
}

.center-div{
  margin: 0 auto;
}

.full-label{
  text-align: left !important;
  padding-bottom: 10px;
  padding-top: 0px !important;
}

.header-flex{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.activated{
  border: 2px solid #017ebc;

  td{
    vertical-align: middle;
  }
}

.flex-v-center{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-groups{
  .dropdown-menu{
    z-index: 9999999;
    min-width: 100px;
  }

}

.custom-wiz{
  opacity: 0.9;
  border-radius: 0;
}

.clearfix{
  clear: both;
}

.profile-data-panel{
  width: 100%;
  max-width: 650px;
  padding: 0;
}

.teams-actions-col{
  width: 70px;
}

.teams-lists{
  .active{
    input{
      color: #333;
    }
  }
}

.footer-page-wrapper{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  span{
    border: 1px solid #ddd;
    padding: 4px 5px;
    border-left: none;
    margin-top: -5px;
    display: inline-block;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }

  .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }

  .paginate-back{
    border-left: 1px solid #ddd;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    margin-top: 20px;
  }
}

.participants{
  padding: 15px;
  max-width: 650px;
  width: 100%;
  padding-left: 45px;

  .participate{
    width: 100%;
    overflow-x:auto;
  }

  .participant-content{
    min-width: 580px;

    .col-md-2, .col-md-1{
      border: 1px solid #aaa;
      padding: 0;
      width: 100%;
    }

    .col-md-2{
      border-right: 0;
      width: 100%;
    }

    h3{
      border-bottom: 1px solid #aaa;
      min-height: 35px;
      color: #fff;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      margin: 0;
      vertical-align: middle;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 100;
      padding: 0 5px;
      font-size: 13px;
      font-weight: normal;
    }

    ul{
      padding: 5px 5px 5px 17px;
      /*list-style: none;*/
      margin: 0;
      list-style-type: square;

      span{
        margin-left: -5px;
      }
    }

    .high-value{
      min-height: 120px;
      padding: 10px 5px;
    }

    .low-value{
      min-height:90px;
      padding: 10px 5px;
    }

    .integrator-space{
      min-height: 50px;
      border-right: 1px solid #aaa;
      border-left: 1px solid #aaa;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      //max-width: 561px;
      padding-right: 0px;
      padding-left: 0px;

      .col-md-1{
        border-top: 0;
        border-bottom: 0;
        border-right: 0;

        display: -webkit-flex;

        display: -ms-flexbox;

        display: flex;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;

        width: 137px;

        @media only screen and (max-width:910px){
          width: 134px;
        }
      }
    }

    .low-bottom{
      min-height:90px;
    }

    .low-bottom, .labels .high-value{
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
      vertical-align: center;
    }

    .high-value strong, .low-value strong, .low-bottom strong{
      font-size: 1.1em;
    }

    .header-part, .footer-part {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }

    .footer-part{
      clear: both;
    }
  }
}

.pad-bt-0{
  padding-bottom: 0 !important;
}

ul.inline-list {
  padding-left: 0;
  list-style: none;
  padding-top: 5px;

  li{
    display: inline-block;
    margin-right: 15px;
  }
}

.profile-description{
  span{
    font-weight: bold;
    color: #666 !important;
    padding: 0;
    font-size: 1.2em;
  }
}

.hiddenRow {
  padding: 0 !important;
}

#tab-1.parent-tab{
  /* min-height: 900px;*/
  /* height: auto;*/
}

.teammates-list{
  tr{
    /*display: none;*/
  }
  &.parent-active{
    tr{
      display: none;
    }

    .active{
      display: table-row;
    }
  }

  .active{
    /*display: table-row;*/

    td{
      background: $active-class !important;
      color: #fff;

      a{
        color: #fff;
      }
    }
  }

  .client-link{
    text-transform: capitalize;
  }
}

.teams-lists {
  .team-actions {
    position: relative;

    .btn-xs {
      background-color: $dark-gray;
      border-color: $dark-gray;
      display: none;
      position: absolute;
    }

    .btn-xs:nth-child(2n){
      left: 50%;
    }

    .btn-xs.btn-danger:hover, .btn-xs.btn-danger:active {
      background-color: $red;
      border-color: $red;
    }

    .btn-xs.btn-info:hover, .btn-xs.btn-info:active {
      background-color: $lazur;
      border-color: $lazur;
    }

    .btn-xs.btn-warning:hover, .btn-xs.btn-warning:active{
      background-color: $yellow;
      border-color: $yellow;
    }

    .btn-xs.btn-primary:hover, .btn-xs.btn-primary:active{
      background-color: $navy;
      border-color: $navy;
    }
  }

  tr:hover .team-actions .btn-xs{
    display: inline-block;
  }

  tr.active .team-actions .btn-xs{
    display: inline-block;
  }
}

.pos-center{
  position: absolute;
  left: 0;
  right: 0;
}

.auto-width{
  width: auto !important;
}

.connection-inner{
  font-family: $font-museo;

  &.ng-animate{
    //@include prefix(transition, all 300ms linear 0ms, webkit ms moz);
  }

  &.ng-hide-remove{
    opacity: 1;
  }

  &.ng-hide-add, &.ng-hide{
    opacity: 0;
  }

  .ibox-content{
    border:none;
  }
}

.or-font{
  font-family: $font-stack;
}

.client-avatar{
  .fa{
    font-size: 20px;
  }
}

.right-buttons{
  .label{
    font-family: $font-stack;
  }
}

.fs-grey-bg{
  background: $color-neutral-dark;
  color: #fff;
}

.right-label-text{
  .eq-clarifier{
    background-color: $clarify-color !important;
  }

  .eq-developer{
    background-color: $develop-color !important;
  }

  .eq-ideator{
    background-color: $ideate-color !important;
  }

  .icon-plus{
    width: 32px;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    color: #929394;


    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-plus:before{
    content: "\f067";
  }

  .eq-implementer{
    background-color: $implement-color !important;
  }
}

.team-profile-top-actions{
  padding: 0;
  margin: 0;

  li{
    list-style: none;

    a{
      font-size: 15px;
    }
  }
}

.profile-carets{
  margin-left: 14px;
}

.common-label b {
  font-weight: normal;
}

.top-levels{
  li{
    margin: 0px 5px;
    &:first-child{
      padding-top: 0px;
    }
  }
}

.user-profile-wrapper{
  i.fa-pencil, i.fa-floppy-o, .fa-times-circle{
    font-size: 16px;
  }
}

.user-profile-icon-wrapper{
  margin-top: -12px;
  margin-left: 19px;

  .unliked-profile{
    display: inline-block;
    margin-top: 20px;
  }
}

.combined-profiles{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 15px;
  margin: 0;
  /*max-width: 500px;*/
  /*width: 100%;*/

  li{
    text-align: center;
    list-style: none;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;

    div{
      margin: 4px auto;
      float: none;
    }
  }
}

.loginscreen{
  .formerror{
    position: relative;
    text-align: left;
    border-radius: 0px;
    padding: 10px;
    padding-left: 35px;

    .fa{
      position: absolute;
      left: 10px;
      font-size: 20px;
      top: 50%;
      margin-top: -10px;
    }
  }
}

.new-dashboard{
  @media (min-width:680px){
    .col-sm-3{
      max-width: 150px;
    }
  }
}

.editable-space{
  padding-right: 45px;
  text-align: justify;
  position: relative;
  .form-group &{
    padding-right: 60px;
  }
  .about-text &{
    padding-left: 0px;
  }
}
.edit-options {
  position: absolute;
  top: 0px;
  right: 0;

  .form-group &{
    right: 15px;
  }

  a {
    width: 30px;
    height: 30px;
    margin: 0 !important;
    text-align: center;
    padding: 0;
  }
}

.w100{
  width: 100%;
}

.max100{
  max-width: 100%;
}

body.body-canvas{
  .team-profile-top-actions, .group-profile .bottom-labels .common-label:before{
    display: none !important;
  }
}

.btn-group-justified.editable-space{
  display: block;
  .btn-group{
    a{margin-right: 5px;}
  }
}
.profile-content{
  .edit-options{
    display: inline-block;
    position: relative;
    margin-left: 10px;
  }
}

.owner-date-container{
  // margin-top: 18px;

  .col-md-6{
    padding-left: 0px;
  }
}

.ibox-content.pad015.border-left-right {
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;

  .inner-relative{
    position: relative;
  }

  .change-profile{
    position: absolute;
    left: 0px;
    color: #fff;
    font-size: 23px;
    width: 100%;
    bottom: 0px;
    padding: 5px 0px;
    background: rgba(0,0,0,0.4);
    display: none;
    text-align: center;
  }

  &:hover, &:active, &:focus{
    .change-profile{
      display: block;
    }
  }
}

.clients-list{
  .tab-pane{
    height: auto;
  }

  .tab-pane.hide-inner-panel{
    height: 600px;
  }

  table tr td {
    height: auto;
  }
}

.inline-right{
  max-width: 70%;
  float: left;
}

.table-org-teams.table{
 tbody{
   tr.active{
     border-top: 15px solid #fff;

     td{
       background: $fs-dark-grey !important;
       background-color: $fs-dark-grey !important;
       color: #fff;

       &:hover, &:active, &:focus{
         background: $fs-dark-grey !important;
         background-color: $fs-dark-grey !important;
       }
     }

     &:hover, &:active, &:focus{
       td{
         background: $fs-dark-grey !important;
         background-color: $fs-dark-grey !important;
       }
     }

     a{
       color: inherit;
     }

   }

   tr.inner-active{
     .bg-fs-grey{
       border-top: none;
     }
   }

   .bg-fs-grey{
     .ibox{
       margin-bottom: 0px;
     }

     .ibox-content{
       padding-bottom: 0px;
     }

     .table{
       margin-bottom: 0px;
     }
   }
 }
}

.fname-column, .lname-column{
  text-transform: capitalize;
}

.dashboard-wrapper{
  .car-big-heading{
    img{
      max-width: 175px;
      clear: both;
      display: block;
      margin: 0 auto;
    }
  }
}

.btn-develop{
  color: #fff;
  background: $develop-color;
  border-color: $develop-color;

  &:hover{
    background: $develop-color;
    /*text-transform: uppercase;*/
    border: transparent;
  }
}

.advice-wrapper{
  .client-detail{
    height: auto;
  }

  .comparison-chart-2{
    margin: 0px 15px;
  }

  .comparison-chart-1{
    margin-left: 15px;
  }
}

.label-orgs{
  max-width: 80px;
  white-space: pre;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  margin-bottom: -5px;
}

.group-member-name{
  margin-top: -10px;
  display: inline-block;
  margin-bottom: 5px;
}

.no-pad-left{
  padding-left: 0 !important;
}

// .header-back{
//   padding-top: 30px;
//   text-align: right;

//   @media(min-width:769px){
//     float: left;
//     width: 16.66667%;
//   }
// }


.page-heading{
  .col-lg-10{
    /*float: left;
    width: 75%;*/
    &.header-full{
      h2{
        padding-right: 0px;
      }

      @media only screen and (min-width:769px){
        width: 60%;
      }

      @media only screen and (max-width:768px){
        width: 100%;
        h2{
          padding-right: 50px;
        }
      }
    }
  }

  .header-back {
    width: 25%;
    float: left;
    text-align: right;
    padding-right: 0;
    padding-top: 30px;

    @media only screen and (max-width:768px){
      padding-top: 50px;
    }

    &.full-screen-option{
      width: 100%;
      padding-top: 10px;
      padding-right: 15px;

      @media only screen and (min-width:769px){
        width: 40%;
        padding-top: 30px;
      }

      @media only screen and (max-height:736px) and (orientation:landscape){
        padding-top: 0px;
        float: right;
        width:50%;
        margin-top: -20px;
      }
    }
  }
}

.sidebar-content{
  .sidebar-panel{
    width: 220px;
    background: #f3f2f3;
    padding: 10px 20px;
    position: relative;
    right: 0;
    height: 100%;
    float: right;
    margin-right: -15px;

    .list-group{
      .list-group-item{
        a{
          word-break: break-word;
        }
      }
    }
  }
}

.no-overflow{
  overflow: hidden;
}

.source-owner{
  .source-wrapper{
    padding-bottom: 10px;
  }

  .widget-head-color-box {
    //background: #6e6e6e;
    padding-bottom: 48px;

    .badges {
      li {
        width: 49%;
      }
    }
  }


  .list-unstyled{
    text-align: left;
    max-width: 130px;
    margin: 0 auto;
    font-family: $muso-sans;
    line-height: 1.5em;

    a{
      color: inherit;
    }
  }

  .inner-profile{
    margin-bottom: 20px;

    .profile-2x{
      display: inline-block;
      margin-left: 12px;
    }

    .fs-in{
      background: #6e6e72;
      color: #fff;
      margin-left: 20px;
      padding: 5px;
    }
  }
}

.rating-wrap{
  .build-form{
    max-width: 350px !important;

    h3{
      font-size: 16px;
      line-height: 1.2em;
    }
  }
}

.profile-heading{
  margin-left: -20px;
  width: 105%;

  h3 {
    font-family: $font-stack;
  }
}

.feedback-top {
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
  padding-right: 30px;
}

.pfizer-dash-wrap{
  .product-imitation{
    width: 170px;
    height: 170px !important;
    max-height: 160px !important;

    -webkit-flex-direction: column;

    -ms-flex-direction: column;

    flex-direction: column;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    border: 1.5px solid #ccc;
    box-shadow: 10px 12px 10px #ccc;

    img{
      max-width: 50px;
    }

    h3.car-small-heading{
      font-family: 'Marydale';
      font-size: 26px;
    }
  }

  .product-box:hover, .product-box.active {
    /*border: none;*/
    box-shadow: none;
  }

  .product-box{
    margin-top: 10px;
  }
}

.dash-admin-4 {
  .car-big-heading {
    margin-top: 18px;
  }

  .top-levels{
    li:not(.last-level){
      /*display: none !important;*/
    }
  }

  .dashboard-wrapper .car-big-heading img {
    max-width: 275px;
  }
}

.color-white{
  color: #fff;
}

.not-strong{
  font-weight: normal;
}

.empty-content-wrapper{
  display: block;
  height: 40px;
  border: 1px solid #ccc;
}

.about-condition{
  width: calc(100% - 15px);
}

.invite-padding {
  margin-top: 10px;
  margin-bottom: 0px;
}

.share-org-state{
  display: inline;
  width: auto;
  vertical-align: middle;
  margin-top: -2px !important;
  outline: none;

  :active, :hover{
    outline: none;
  }
}

.type-in-block{
  display: inline-block;
}

.center-block{
  float: none;
}

.forgot-screen{
  .login-wrapper{
    h4{
      line-height: 1.4em;
    }
  }
}

.grey-link{
  color: $text-color;
}

.stop-link{
  pointer-events: none;
  cursor: default;
  //opacity: 0.4;

  *{
    pointer-events: none;
    cursor: default;
  }
}

.linked-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 250px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  a {
    max-width: 60%;
    float: none;
    margin: 0 auto;
    height: auto;
  }
}

@media (min-width: 680px){
  .dash-admin-4 .new-dashboard .col-sm-3 {
    max-width: 200px;
  }
}

.circled-preference{
  border: 2px solid;
  border-radius: 65% 65%;
  padding: 20px 0px;
}

.dash-pfizer{
  .course-index {
    > h2{
      text-align: center;
      width: 100%;
    }

    > hr{
      padding: 0;
    }
  }
}

.above-side-nav{
  padding: 14px 20px 10px 25px;
  img{
    max-width: 70%;
  }
}

.fs-graph-logo{
  max-width: 300px;
  width: 100%;
}

.ft-inherit{
  font-size: inherit !important;
}

/* Admin Scope*/
//.dash-admin-wrapper, .admin-active, .practitioner-active, .participant-active{
//  display: none !important;
//}

.dash-admin-1, .dash-admin-2 {
  .dash-practitioner-wrapper {
    display: none !important;
  }

  .dash-pfizer{
    display: none !important;
  }

  .practitioner-table{
    display: none !important;
  }
}

.dash-admin-1{
  .dash-admin-wrapper, .admin-active{
    display: block !important;
  }
}

.dash-admin-2{
  .dash-admin-wrapper, .practitioner-active{
    display: block !important;
  }
}

.dash-admin-3{
  .participant-active{
    display: block !important;
  }

  .dash-pfizer{
    display: none !important;
  }

  .admin-table{
    display: none !important;
  }

  .no-participant{
    display: none !important;
  }
}

.dash-admin-4{
  .participant-active{
    display: block !important;
  }

  #side-menu a:not(.home-link){
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
  }
}

.new-dashboard {
  h4.car-small-heading {
    /*font-size: 15px;*/

    @media(min-width:769px) and (max-width:970px){
      font-size: 14px;
    }
  }
}

.single-box-nav{

  @media screen and (max-width: 679px) {
    .text-left a , .text-right a {
      font-size: 0px;
    }
  }

  a{
    font-size: 14px;
    line-height:18px;
    color: #666;
    position:relative;
    display: inline-block;

    .fa {
      font-size: 18px;
      color:#fff;
      vertical-align: bottom;
      position: relative;
      bottom: -10px;
      z-index: 99;

      &:hover {
        color:#1ab394;
      }
    }

    span {
      color:#999999;
    }
  }

  .text-left{

    body.mini-navbar & {
      @media screen and (max-width: 599px) {
        width:20%;
      }

      @media screen and (max-width: 413px) {
        padding: 0px;
      }
    }
    a {
      margin-left: 40px;

      &:before {
        content:"\f138";
        position:absolute;
        left:-36px;
        top:6px;
        font-size: 30px;
        font-family: FontAwesome;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        color:$body-content-color;
      }

      &:hover {

        &:before{
          color:#1ab394;
        }
      }
    }
  }

  .text-center {

    body.body-small & {
      @media screen and (max-width: 413px) {
        min-width:170px;
      }

      @media screen and (max-width: 374px) {
        left:-10px;
      }
    }

    body.mini-navbar & {
      @media screen and (max-width: 599px) {
        min-width:170px;
        left: 5px;
     }

      @media screen and (max-width: 413px) {
        left: -10px;
      }

      @media screen and (max-width: 374px) {
        min-width:130px;
      }
    }

    span.scroll-btn {
      display: block;
      position: relative;

      a {
        position: absolute;
        display: block;
        max-width: 140px;
        text-align: center;
        left: 0;
        background: #666;
        top: 0;
        color: #fff;
        padding: 8px 8px 0;
        right: 0;
        margin: auto;

        &:after {
          content: "";
          height: 0px;
          border-top: 20px solid #666;
          border-right: 70px solid transparent;
          border-left: 70px solid transparent;
          position: absolute;
          width: 100%;
          left: 0;
          right: 0;
          bottom: -20px;

          @media screen and (max-width: 374px) {
            body.mini-navbar &{
              border-right: 50px solid transparent;
              border-left: 50px solid transparent;
            }
          }
        }
      }
    }
  }

  .text-right{
    body.body-small & {
      @media screen and (max-width: 413px) {
        width:20%;
        float: right;
      }
    }

    body.mini-navbar & {
      @media screen and (max-width: 599px) {
        width:20%;
        float: right;
      }

      @media screen and (max-width: 413px) {
        padding: 0px;
        float: right;
      }
    }
    a {
      margin-right: 40px;

      &:after {
        content:"\f138";
        position:absolute;
        right:-36px;
        top:6px;
        font-size: 30px;
        font-family: FontAwesome;
        color:$body-content-color;
      }

      &:hover {

        &:after{
          color:#1ab394;
        }
      }
    }
  }
}

/*V1 Dashboard Updates*/

.dash-admin-4{
  .dash-practitioner-wrapper{
    display: none;
  }

  .dash-pfizer{
    display: block;
  }
}

/*.dash-admin-4 .single-section-wrap, .dash-admin-4 .lecture-small-box, .dash-admin-4 .dashboard-wrapper .arrow-icons{*/
/*  opacity: 0.4;*/
/*}*/
/**/
/*.dash-admin-4 .pre-work-column .lecture-small-box, .dash-admin-4 .pre-work-column{*/
/*  opacity: 1;*/
/*}*/
/**/
/*.dash-admin-4 .dashboard-wrapper .popover.bottom{*/
/*  opacity: 0;*/
/*}*/
/**/
/*.dash-admin-4 .dashboard-wrapper .new-dashboard .row .pre-work-column .lecture-small-box .popover.bottom{*/
/*  opacity: 1;*/
/*}*/

.loginscreen{
  padding-top: 150px;

  div.logo-wrapper{
    img{
      width: 350px;
      margin-left: -33px;
      margin-bottom: 20px;
    }

    small{
      text-align: right;
      display: block;
      color: #fff;
      margin-top: -30px;
      margin-bottom: 30px;
      clear: both;
    }
  }

  .login-wrapper {
    /*margin-top: 35px;*/
  }
}

.page-heading{
  position: relative;
  @media(max-width:768px){
    h2{
      font-size: 20px;
    }
  }
}

.login-on-register, .login-on-login{
  color: #fff;
}

.modal {
  z-index: 20509999999 !important;
}

form.m-t{
  .help-block{
    margin-top: -16px;
    padding: 5px 0px;
    color: #ed5565;
    background: #f2dede;
  }
}

.above-side-nav small {
  display: block;
  clear: both;
  max-width: 122px;
  text-align: right;
  font-size: 10px;
  color: #fff;
}

.nav-header {
  padding: 29px 20px;
  .neg-margin {
    margin-left: -20px;
    margin-right: -20px;
    &:nth-last-child(2) {
      margin-bottom: 10px !important;
    }
    
  }
  

}

.upload_button_holder{
  position: absolute;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  margin-top: -33px;
  /*max-width: 369px;*/

  .upload_button{
    position: relative;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    background: rgba(0,0,0,0.4);
    color: #fff;
    padding: 5px 0;
    /* border: 1px solid #000; */
    border-radius: 0px;
    width: 100%;
    height: 32px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    display: block;
  }

  .cloudinary_fileupload{
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
  }
}


/*----custom css for left side nav-------------*/
.navbar-static-side{
  top: 0;
  bottom: 0;
  position: fixed;

  @media(min-width: 768px) and (max-width:770px){
    z-index: 0;
    &.mini-navbar {
      z-index: 99999;
    }
  }


  .nav-header{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .trigger-mini-bar{
    position: absolute;
    right: 0;
    top: 0px;
    @media(max-width:768px){
      display: none !important;
    }
  }

  .search-side-nav{
    .navbar-form-custom{
      display: block !important;
    }
  }

  ul#side-menu{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    top:75px;
    bottom: 0;
    width: 100%;

    @media(max-width:768px){
      top:50px;
    }

    .nav-spacer{
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      min-height: 12px;
    }
  }
}

.dropdown{
  &.sidenav-footer{
    .nav-pop{
      display: none;
      .iradio_square-green{
        margin-right: 5px;
      }
      &.navbar-top-links a{
        font-size: 13px;
        padding: 12px 10px;
      }
    }

    &.open{
      .nav-pop{
        display: block;
        float: none !important;
        margin: 0 auto !important;
        position: absolute;
        bottom: -10px;
        right: -233px;
        max-width: 220px;
        padding: 0 15px;
        border: 1px solid #2f4050 !important;
        background: #2f4050;
        box-shadow: 0 0 5px rgba(0,0,0,0.5);

        li{
          display: block;
          width:100%;

          .top-levels{
            display: block !important;
            padding: 0px;
            li{
              padding: 5px 0px;
              color: #fff;
            }
          }
        }
      }
    }
    .trigger-nav-pop {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: right;
      background: transparent !important;
    }
  }
}

.mb-25{
  margin-bottom: 25px;
}

.single-card-link, .text-link{
  cursor: pointer;
}

.profile-centered{
  max-width: 34px;
  margin: 0 auto;
  margin-top: -4px;

  a{
    margin-left: -6px;
    display: inline-block;
  }
}

.top-right{
  position: absolute;
  top: 10px;
  right: 25px;
}

.fs-10{
  font-size: 10px;
}

.fs-in{
  font-size: inherit;
}

.learner-map-max{
  max-width: 300px;
  margin: 0 auto;
}

.linkedin-width{
  @media (max-width: 1400px) {
    max-width: 215px;
  }

}

.mini-navbar{
  .above-side-nav{
    padding-top: 0px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;

    img{
      opacity: 0;
    }
    small{
      text-align: center;
    }
  }
  .trigger-mini-bar{
    position: relative;
    a{
      margin: 10px 0 -5px 0px;
      float: none;
    }
  }
  .nav-pop{
    right:-212px !important;
    bottom:0px !important;
  }

  .metismenu{
    .collapsing{
      position:absolute;
    }
    .collapse{
      opacity: 0;
      &.in{
        opacity: 1;
      }
    }
  }
}


.course-index .product-imitation{
  @media(min-width:769px) and (max-width:970px){
    font-size: 15px !important;
  }
}

.new-dashboard .col-sm-3{
  @media(min-width:769px) and (max-width:970px){
    padding: 0 5px;
  }
}

.num-list{
  @media(max-width:767px){
    .num-p{
      margin-bottom: 10px;
    }
  }
}

.group-profile-img {
  max-width: 34px;
  height: 15px;
  margin-left: 6px;
}

.all-toolcards .innovation-section.toolcards{
  blockquote.col-xs-8.pull-right{
    @media only screen and (min-width:769px) and (max-width:920px){
      margin-bottom: 45px;
    }
  }
}

.button-wrapper-flex-right{
  display: flex;
  text-align: right;
  align-items: flex-end;
  justify-content: flex-end;
  height: 42px;
  padding-right: 0;
}

.theme-config{
  .clear-group{
    font-size: 65%;
    position: absolute;
    right: 7px;
    top: 13px;
  }
}

.profile-map{
  min-width:800px;
  max-width:800px;

  @media only screen and (max-width:1023px){
    min-width:inherit;
    width: 100%;
    text-align: center;
  }

  .profile-map-img{
    min-width:500px;
    max-width:500px;
    float: left;
    @media only screen and (max-width:1023px){
        float: none;
        margin: 0 auto;
    }
    @media only screen and (max-width:736px){
        min-width: inherit;
    }
  }

  .profile-map-link{
    min-width:258px;
    max-width:258px;
    float: left;
    @media only screen and (max-width:1023px){
        float: none;
        margin: 0 auto;
    }
    @media only screen and (max-width:736px){
        min-width: 200px;
        max-width: 200px;
    }
  }
}



/* ---- stats.js ---- */
canvas{
  display:block;
  vertical-align:bottom;
}

.count-particles{
  background: #000022;
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  color: #13E8E9;
  font-size: .8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.js-count-particles{
  font-size: 1.1em;
}

#stats,
.count-particles{
  -webkit-user-select: none;
  margin-top: 5px;
  margin-left: 5px;
}

#stats{
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.count-particles{
  border-radius: 0 0 3px 3px;
}

.capitalize {
  text-transform: capitalize;
}

.password-form{
  .help-block{
    margin-top: 0px;
    padding: 5px;
    color: #ed5565;
    background: #f2dede;
  }

  .control-label{
    padding-top: 0px;
  }
}

.widget-head-color-box{
  .badges{
    list-style: none;
    margin: 20px auto 0px;
    text-align: center;
    padding: 0px;

    li{
      width: 90px;
      text-align: center;
      display: inline-block;
    }
  }
}

.cursor-pointer{
  cursor: pointer;
}

.columns-2{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.bg-fs-grey{
  background: $fs-dark-grey;
}

.allow-with-newlines {
  white-space: pre-wrap;
}


/* ---- particles.js container ---- */

#particles-js{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left: 0;
  top:0;
  right: 0;
  bottom: 0;
}

.full-select{
  .chosen-container{
    width: 100% !important;
  }
}

.navbar-static-side {
  .nav-header {
    padding-bottom: 30px;
  }
}

.mt-30{
  margin-top: 25px;
}


  // Adobe Account
.adobe-account{
  .sidebar-home, .sidebar-collaboration, .sidebar-teams, .sidebar-network, .sidebar-groups, .sidebar-fs-learner, .sidebar-sharing, .sidebar-admin{
    display: none !important;
  }
}

.arrow-column{
  width: 32px;
}

.wide-per-page{
  width: 65px;
}

.no-wrap{
  white-space: nowrap;
}

.mt-b-10{
  margin-bottom: 10px;
}

a:hover, a:focus {
  color: $develop-color;
}

@media (max-width: 1565px){
  .clear-list .pull-right {
    word-wrap: break-word;
    max-width: 50%;
  }
}

//Change Button Color with FS Developer
.btn-primary {
  background-color: $develop-color;
  border-color: $develop-color;

  &:hover, &:focus, &:active{
    background-color: #4cae4c;
    border-color: #4cae4c;
  }

  &[disabled]{
    background-color: #4cae4c;
    border-color: #4cae4c;

    &:hover, &:focus, &:active{
      background-color: #4cae4c;
      border-color: #4cae4c;
    }
  }
}

// BOC
.boc{
  #dash2{
    .badges, .dash-collab-edit{
      display: none !important;
    }
  }

  .dash-leaner-maps-table, .dash-network-table{
    display: none !important;
  }
}

.nav-boc{
  .sidebar-collaboration, .sidebar-teams, .sidebar-network, .sidebar-learner-maps, .sidebar-collab-short{
    display: none !important;
  }

  .sidebar-innovation, .sidebar-toolcards, .sidebar-ucp{
    display: block !important;
  }
}

.innovation-download-right{
  margin-top: 10px;
  flex-direction: column;
}



/*==========================================================*/





/* Only demo */

@media (max-width: 1000px) {
    .welcome-message {
        display: none;
    }
}

.btn-primary {
    background-color: #73BF38;
    border-color: #73BF38;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background-color: #4cae4c;
    border-color: #4cae4c;
}

.dashboard-column{
    .source-owner{
        .btn-primary{
            width: 130px;
        }
    }
}

// Menu
.metismenu {
    ul.nav.navbar-top-links {
        display: inline-block;
        left: 195px;
        top: 15px;
        li {
            width: 100%;
            margin-right: 0px;
            a {
                padding: 10px 10px;
                min-height: 10px;
            }
        }
    }
    .navbar-top-links.collapse {
        display: none !important;
    }
}

.clearfix {
    clear: both;
}

.mt-30 {
    margin-top: 30px;
}

.auth0-lock.auth0-lock .auth0-lock-header-logo {
    max-width: 220px;
    margin-bottom: 0px !important;
}

.auth0-lock.auth0-lock .auth0-lock-name {
    margin: -14px auto 0;
    font-size: 8px !important;
    max-width: 220px;
    text-align: right;
    color: #636363 !important;
}

.auth0-lock.auth0-lock .auth0-lock-header {
    height: 92px !important;
}

// ParticleJS
#particles-js {
    display: none;
}

.auth0-lock-html {
    #particles-js {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
    }
    .auth0-lock-overlay {
        display: none !important;
    }
}

.button-wrapper-flex-right {
    display: -ms-flexbox;
    display: flex;
    text-align: right;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 42px;
    padding-right: 0;
    flex-direction: column;
    align-items: center;
}


.pos-relative{
  position: relative;
}

.m-b-lg.pos-relative > .label{
  /*position: absolute;*/
  right: 10px;
  z-index: 9999;
  float: right;
}

.no-pad-left{
  padding-left: 0 !important;
}
.not-strong {
    font-weight: 400;
}
.empty-content-wrapper{
  display: block;
  height: 40px;
  border: 1px solid #ccc;
}

.about-condition{
  width: calc(100% - 15px);
}
.no-overflow {
    overflow: hidden;
}
.no-margin {
    margin: 0;
}
.no-mg-top{
  margin-top: 0 !important;
}

.grey-link{
  color: $text-color;
}



.single-box-nav{

  @media screen and (max-width: 679px) {
    .text-left a , .text-right a {
      font-size: 0px;
    }
  }

  a{
    font-size: 14px;
    line-height:18px;
    color: #666;
    position:relative;
    display: inline-block;

    .fa {
      font-size: 18px;
      color:#fff;
      vertical-align: bottom;
      position: relative;
      bottom: -10px;
      z-index: 99;

      &:hover {
        color:#1ab394;
      }
    }

    span {
      color:#999999;
    }
  }

  .text-left{

    body.mini-navbar & {
      @media screen and (max-width: 599px) {
        width:20%;
      }

      @media screen and (max-width: 413px) {
        padding: 0px;
      }
    }
    a {
      margin-left: 40px;

      &:before {
        content:"\f138";
        position:absolute;
        left:-36px;
        top:6px;
        font-size: 30px;
        font-family: FontAwesome;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        color:$body-content-color;
      }

      &:hover {

        &:before{
          color:#1ab394;
        }
      }
    }
  }

  .text-center {

    body.body-small & {
      @media screen and (max-width: 413px) {
        min-width:170px;
      }

      @media screen and (max-width: 374px) {
        left:-10px;
      }
    }

    body.mini-navbar & {
      @media screen and (max-width: 599px) {
        min-width:170px;
        left: 5px;
     }

      @media screen and (max-width: 413px) {
        left: -10px;
      }

      @media screen and (max-width: 374px) {
        min-width:130px;
      }
    }

    span.scroll-btn {
      display: block;
      position: relative;

      a {
        position: absolute;
        display: block;
        max-width: 140px;
        text-align: center;
        left: 0;
        background: #666;
        top: 0;
        color: #fff;
        padding: 8px 8px 0;
        right: 0;
        margin: auto;

        &:after {
          content: "";
          height: 0px;
          border-top: 20px solid #666;
          border-right: 70px solid transparent;
          border-left: 70px solid transparent;
          position: absolute;
          width: 100%;
          left: 0;
          right: 0;
          bottom: -20px;

          @media screen and (max-width: 374px) {
            body.mini-navbar &{
              border-right: 50px solid transparent;
              border-left: 50px solid transparent;
            }
          }
        }
      }
    }
  }

  .text-right{
    body.body-small & {
      @media screen and (max-width: 413px) {
        width:20%;
        float: right;
      }
    }

    body.mini-navbar & {
      @media screen and (max-width: 599px) {
        width:20%;
        float: right;
      }

      @media screen and (max-width: 413px) {
        padding: 0px;
        float: right;
      }
    }
    a {
      margin-right: 40px;

      &:after {
        content:"\f138";
        position:absolute;
        right:-36px;
        top:6px;
        font-size: 30px;
        font-family: FontAwesome;
        color:$body-content-color;
      }

      &:hover {

        &:after{
          color:#1ab394;
        }
      }
    }
  }
}

.or-font{
  font-family: $font-stack;
}

.type-in-block{
  display: inline-block;
}

.center-block{
  float: none;
}

.page-heading{
  position: relative;
  z-index: 99;
  @media(max-width:768px){
    h2{
      font-size: 15px;
    }

    .breadcrumb {
      li {
        font-size: 10px;
      }
    }
  }
}


.page-heading {

  @media #{$mobile} { 

    .col-lg-10 {
      width: 100%;
    }

  }
}


footer {

  @media #{$mobile} {

    padding-block: 15px;

  .custom-footer {
      .text-right {
        text-align: center;
      }
    }
  }
}