@import './../../assets/styles/variables';

:root {
  --gap-point5: calc(var(--gap-default) / 2);
  --gap-ten: 10px;
  --gap-default: 15px;
  --gap-default-minus: calc(-1 * var(--gap-default));
  --gap-2: calc(2 * var(--gap-default));

  --ffSecondary: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

.f-flex {
  display: flex;
  flex-wrap: wrap;
  margin-inline: var(--gap-default-minus);

  &-only {
    display: flex;
    flex-wrap: wrap;
    margin-inline: 0;
  }

  &-nowrap {
    flex-wrap: nowrap;
  }

  &--lessgap {
    --gap-default: 5px;
  }

  &--lessgap-1 {
    --gap-default: 8px;
  }

  &--colgapexist {

    &>[class*="f-col-"] {
      margin-bottom: var(--gap-2);
    }
  }
}

.f-flex--nogap {
  margin-inline: 0;
}

.f-grid {

  &-gap-6 {
    grid-gap: 6px;
  }

  &-gap-10 {
    grid-gap: 10px;
  }

  &-row-gap-6 {
    grid-row-gap: 6px;
  }

  &-row-gap-10 {
    grid-row-gap: 10px;
  }

  &-row-gap-16 {
    grid-row-gap: 16px;
  }
}

@for $i from 1 through 12 {
  .f-col-#{$i} {
    width: calc(100% * $i / 12);
    padding-inline: var(--gap-default);

    &-till-m {
      width: calc(100% * $i / 12);
      padding-inline: var(--gap-default);
    }

    &-till-pc {
      width: 100%;

      @media #{$pc-up} {
        width: calc(100% * $i / 12);
        padding-inline: var(--gap-default);
      }
    }

    &-till-xl {
      width: 100%;

      @media #{$xl-up} {
        width: calc(100% * $i / 12);
        padding-inline: var(--gap-default);
      }
    }

    @media #{$mobile} {
      width: 100%;
    }
  }
}

.f-col-w-full-pc {
  @media #{$pc} {
    width: 100%;
  }
}

.f-col-w-full-md {
  @media #{$tablet} {
    width: 100%;
  }
}

.f-flex--nogap>* {
  padding-inline: 0;
}

.f-w-full {
  width: 100%;

  &-imp {
    width: 100% !important;
  }

}

.f-w-full-md-up {
  @media #{$tablet-up} {
    width: 100%;
  }
}

.f-w-full-md {
  @media #{$tablet} {
    width: 100%;
  }
}

.f-w-full-m-up {
  @media #{$mobile-up} {
    width: 100%;
  }
}

.f-w-full-m {
  @media #{$mobile} {
    width: 100%;
  }
}

.f-w-full-np {
  @media #{$mobile} {
    padding-inline: 0;
  }
}

.f-h-full {
  height: 100%;
}

.f-min-h-full {
  height: 100%;
}

.list-none {
  list-style: none;
}

.f-d-block {
  display: block;
}

.f-d-none {
  display: none;
}

.f-flex-1 {
  flex: 1;
}

.f-table-responsive {
  @media #{$mobile} {
    margin-bottom: 15px;
    overflow-x: auto;
  }
}

$fontWeight: (
  normal: 400,
  medium: 500,
  semi: 600,
  bold: 700
);

@each $fwProp ,
$fwVal in $fontWeight {
  .f-weight-#{$fwProp} {
    font-weight: $fwVal;
  }
}

@function fontWeight($fontWeight) {
  @return map-get($fontWeight , $fontWeight);
}

$lineHeight: (
  none: 1,
  tight: 1.375,
  normal: 1.5,
  relaxed: 1.75,
  loose: 2
);

@each $lHprop,
$lHVal in $lineHeight {
  .f-leading-#{$lHprop} {
    line-height: $lHVal;
  }
}

@function lineHeight($lineHeight) {
  @return map-get($lineHeight , $lineHeight);
}

$justifyContent: (
  start: flex-start,
  center: center,
  end: flex-end,
  space-bwn: space-between
);

@each $jCProp,
$jCVal in $justifyContent {
  .f-justify-#{$jCProp} {
    justify-content: $jCVal;

    &-md-up {

      @media #{$tablet-up} {
        justify-content: $jCVal;
      }
    }

    &-d {

      @media #{$mobile-up} {
        justify-content: $jCVal;
      }
    }

    &-m {

      @media #{$mobile} {
        justify-content: $jCVal;
      }
    }
  }
}

$alignItems: (
  start: flex-start,
  center: center,
  end: flex-end
);

@each $aIProp,
$aIVal in $alignItems {
  .f-items-#{$aIProp} {
    align-items: $aIVal;

    &-md-up {

      @media #{$tablet-up} {
        align-items: $aIVal;
      }
    }

    &-d {
      @media #{$mobile-up} {
        align-items: $aIVal;
      }
    }

    &-m {

      @media #{$mobile} {
        align-items: $aIVal;
      }
    }

  }
}

$flow: (
  point5: var(--gap-point5),
  10: var(--gap-ten),
  default: var(--gap-default),
  2: var(--gap-2),
  fourty: 40px,
  fifty: 50px
);

@each $fProp,
$fVal in $flow {

  .f-flow-y-all {

    &-#{$fProp}>* {
      margin-bottom: $fVal;
    }

    &-#{$fProp}-d>* {
      @media #{$mobile-up} {
        margin-bottom: $fVal;
      }
    }
  }

  .f-flow-y {

    &-#{$fProp}>*:not(:last-child) {
      margin-bottom: $fVal;
    }

    &-#{$fProp}-md>*:not(:last-child) {

      @media #{$tablet} {
        margin-bottom: $fVal;
      }
    }

    &-#{$fProp}-pc>*:not(:last-child) {

      @media #{$pc} {
        margin-bottom: $fVal;
      }
    }

    &-#{$fProp}-d>*:not(:last-child) {
      @media #{$mobile-up} {
        margin-bottom: $fVal;
      }
    }


    &-#{$fProp}-m>*:not(:last-child) {
      @media #{$mobile} {
        margin-bottom: $fVal;
      }
    }

    &-#{$fProp}-rev-m>*:not(:first-child) {
      @media #{$mobile} {
        margin-bottom: $fVal;
      }
    }
  }

  .f-flow-x {

    &-#{$fProp}>*:not(:last-child) {
      margin-right: $fVal;
    }

    &-#{$fProp}-d-up>*:not(:last-child) {
      @media #{$tablet-up} {
        margin-right: $fVal;
      }
    }

    &-#{$fProp}-d>*:not(:last-child) {
      @media #{$mobile-up} {
        margin-right: $fVal;
      }
    }

    &-#{$fProp}-m>*:not(:last-child) {
      @media #{$mobile} {
        margin-right: $fVal;
      }
    }
  }
}

$textAlign: (
  left: left,
  center: center,
  right: right
);

@each $taProp,
$taVal in $textAlign {

  .f-text-#{$taProp} {
    text-align: $taVal;
  }

  @media #{$tablet-up} {
    .f-text-#{$taProp}-md-u {
      text-align: $taVal;
    }
  }

  @media #{$tablet} {
    .f-text-#{$taProp}-md {
      text-align: $taVal;
    }
  }

  @media #{$pc-up} {
    .f-text-#{$taProp}-pc-u {
      text-align: $taVal;
    }
  }

  @media #{$mobile-up} {
    .f-text-#{$taProp}-d {
      text-align: $taVal;
    }
  }

  @media #{$mobile} {
    .f-text-#{$taProp}-m {
      text-align: $taVal;
    }
  }
}

$float: (
  left: left,
  right: right,
  none: none
);

@each $fProp ,
$fVal in $float {
  .f-pull-#{$fProp} {
    float: $fVal;

    &-md-up {

      @media #{$tablet-up} {
        float: $fVal;
      }
    }

    &-m {

      @media #{$mobile} {
        float: $fVal;
      }
    }
  }
}

.f-dir-row-rev {
  flex-direction: row-reverse;

  &-m {
    flex-direction: row-reverse;
  }
}

.f-dir-col {

  flex-direction: column;

  &-rev {

    flex-direction: column-reverse;

    &-m {
      @media #{$mobile} {
        flex-direction: column-reverse;
      }
    }
  }
}

.f-dir-col-md {

  @media #{$tablet} {
    flex-direction: column;
  }
}


$imageWidths: (
  100,
  200,
  250,
  300,
  400,
  500
);

@each $iWVal in $imageWidths {
  .img-w-#{$iWVal} {
    width: #{$iWVal}px;
  }
}

$directions: (
  all,
  top,
  right,
  bottom,
  left,
  block,
  inline
);
$spaces: (
  0,
  7,
  8,
  10,
  15,
  16,
  18,
  20,
  30,
  40,
  50,
  auto
);

@each $dProp in $directions {
  @each $sProp in $spaces {

    $sPropVal: null;

    @if $sProp =='auto' {
      $sPropVal: $sProp;
    }

    @else {
      $sPropVal: #{$sProp}px;
    }

    @if($dProp =='all') {

      .f-p-all-#{$sProp} {
        padding: $sPropVal;
      }

      .f-m-all-#{$sProp} {
        margin: $sPropVal;
      }
    }

    @else {

      .f-p {

        &-#{$dProp}-#{$sProp} {

          padding-#{$dProp}: $sPropVal;

          &-md-up {
            @media #{$tablet-up} {
              padding-#{$dProp}: $sPropVal;
            }
          }

          &-m-up {
            @media #{$mobile-up} {
              padding-#{$dProp}: $sPropVal;
            }
          }

          &-pc {
            @media #{$pc} {
              padding-#{$dProp}: $sPropVal;
            }
          }

          &-md {
            @media #{$tablet} {
              padding-#{$dProp}: $sPropVal;
            }
          }

          &-m {
            @media #{$mobile} {
              padding-#{$dProp}: $sPropVal;
            }
          }

        }

      }

      .f-m-#{$dProp}-#{$sProp} {
        margin-#{$dProp}: $sPropVal;

        &-imp {
          margin-#{$dProp}: $sPropVal !important;
        }

        &-md-up {
          @media #{$tablet-up} {
            margin-#{$dProp}: $sPropVal;
          }
        }

        &-m-up {
          @media #{$mobile-up} {
            margin-#{$dProp}: $sPropVal;
          }
        }

        &-m {
          @media #{$mobile} {
            margin-#{$dProp}: $sPropVal;
          }
        }
      }
    }
  }
}

.pos {

  &-rel {
    position: relative;
  }

  &-abs {
    position: absolute;
  }
}

.a-r {

  &-16-9 {
    aspect-ratio: 16 / 9;
  }

  &-1-1 {
    aspect-ratio: 1 / 1;
  }

}



.media-wrapper {
  aspect-ratio: 16 / 9;
  position: relative;
}

.media-wrapper video,
.media-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: none !important;
  max-height: none !important;
}

.media-wrapper video {
  object-fit: cover;
}

.img {

  &-full {
    max-width: none;
    width: 100%;
    height: 100%;
  }

  &-contain {
    object-fit: contain;
  }

  &-cover {
    object-fit: contain;
  }
}

.f-btn {
  padding-block: 20px;
  padding-inline: 28px;
  background-color: $develop-color;
  border: 1px solid $develop-color;
  color: $clrWhite;
  font-size: 18px;
  line-height: 1.2;
  display: inline-block;
  font-family: $muso-700;
  border-radius: 3px;
  text-decoration: none;
  word-break: break-all;
  text-align: center;
  transition: none;
  cursor: pointer;

  &:not(.f-btn--disabled):focus {
    outline: 1px dashed $color-neutral-dark-500;
    outline-offset: 4px;
  }

  &:not(.f-btn--disabled):hover,
  &:not(.f-btn--disabled):focus {
    background-color: $color-dark-green;
    border-color: $color-dark-green;
    color: $clrWhite;
  }

  &--sm {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 9px;
    padding-inline: 20px;
  }

  &--xsm {
    font-size: 12px;
    padding-block: 5px;
    padding-inline: 12px;
    line-height: 1.5;
  }

  &--xxsm {
    font-size: 12px;
    padding-block: 4px;
    padding-inline: 7px;
    white-space: nowrap;
    font-family: $font-stack;
  }

  &--min-w-160-pc-up {
    @media #{$pc-up} {
      min-width: 160px;
    }
  }

  &--disabled {
    cursor: not-allowed;
    opacity: .65;
    // pointer-events: none;

    &-bg {
      background-color: $color-neutral-light;
      color: $body-content-color;
    }
  }

  &--min-w-160-m-up {
    @media #{$mobile-up} {
      min-width: 160px;
    }
  }

  &--grey {
    background-color: $color-neutral-light;
    border-color: $color-neutral-light;
    color: $color-neutral-dark;
  }

  &--white {
    background-color: #fff;
    border-color: #ddd;
    color: $color-neutral-dark;
  }

  &--zahripink {
    background-color: #ef6776;
    border-color: #ef6776;
    color: #fff;
  }

  &--orange {
    background-color: $ideate-color;
    border-color: $ideate-color;
    color: #fff;
  }

  &--seagrn {
    background-color: #1dc5a3;
    border-color: #1dc5a3;
    color: #fff;
  }

  &--ongoing {
    background-color: $clrWarning;
    border-color: $clrWarning;
    color: $clrWhite;

    i {
      animation: spin 0.3s infinite;
    }
  }

  &--success {
    background-color: #06A6CE;
    border-color: #06A6CE;
    color: #fff;
  }

  &--dngr {
    background-color: #ED5565;
    border-color: #ED5565;
    color: #fff;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: .65;
  }

  &--reset {
    background-color: $clrWarning;
    border-color: $clrWarning;
    color: #fff;
  }
}

.f-font-secondary {
  font-family: var(--ffSecondary);
}

$profileColors: (
  clarify: $clarify-color,
  ideate: $ideate-color,
  develop: $develop-color,
  implement: $implement-color,
  white: $clrWhite,
  gray1: $clrGray1,
  neutral-light: $color-neutral-light
);

@each $pCProp,
$pCVal in $profileColors {
  .f-bg-#{$pCProp} {
    background-color: $pCVal;

    &--hover:hover {
      background-color: lighten($pCVal , 5%);
    }
  }

  .f-color-#{$pCProp} {
    background-color: $pCVal;
  }
}

.f-style {

  &-italic {
    font-style: italic;
  }
}


.f-flex-row-gap {
  
  &-30 {
    grid-row-gap: 30px;
  }
  
}


/****************
    12-December 
****************/

// Breadcrumb

.bread-c {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  margin-inline: 0;

  &>li {

    a {
      color: inherit;
      text-overflow: ellipsis; 
      overflow: hidden; 
      white-space: nowrap;
      max-width: 150px;
      display: inline-block;
    }

    &+li {

      &:before {
        padding: 0 5px;
        color: #ccc;
        content: "/";
      }
    }
  }
}


// Disabled Button

.f-btn {

  &.disabled {
    cursor: not-allowed;
    opacity: 0.65;
    box-shadow: none;
  }
}

// Navigation List

.nav-second-level {

  li {
    display: block;
    position: relative;

    a {
      display: block;
      position: relative;
      font-weight: 600;
    }
  }
}

// Typography

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  line-height: 1.1;
  color: inherit;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;

  &:first-child {
    margin-top: 0;
  }
}

p {
  margin: 0 0 10px;
}

// Table Styles

.table-responsive {

  @media #{$tablet} {
    overflow-x: auto;
  }

  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;

    thead {

      tr {

        th {
          text-align: left;
          border-top: 0;

          input {
            font: inherit;
          }

          .inline-list {
            margin-block: 0;
          }
        }
      }
    }

    tr {

      td {
        border-top: 1px solid #e7eaec;
        // line-height: 1.42857;
        // padding: 8px;
        // vertical-align: top;
      }
    }

    .form-group {
      margin-bottom: 15px;
    }

    label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 700;
    }

    &.table-striped {

      &>tbody {

        &>tr:nth-of-type(odd) {
          background-color: #f9f9f9;
        }
      }
    }
  }
}

// Image Styling

img {
  vertical-align: middle;
}

.f-blk-m {
  @media #{$mobile} {
    display: block;
  }
}

// Mobile Nav Trigger

.visible-xs {
  display: none;

  @media #{$mobile} {
    display: block;
  }
}


// for learner map buttons
.btn-primary {
  padding-inline: 28px;
  background-color: rgb(105, 190, 40);
  border: 1px solid rgb(105, 190, 40);
  color: #fff;
  font-size: 18px;
  line-height: 1.2;
  display: inline-block;
  font-family: "Museo-700";
  border-radius: 3px;
  text-decoration: none;
  word-break: break-all;
  text-align: center;
  transition: none;
  cursor: pointer;

  &.btn-xs{
    font-size: 12px;
    padding-block: 4px;
    padding-inline: 7px;
    white-space: nowrap;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  } 

} 