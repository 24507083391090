/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.7.1
 *
*/

// Google Fonts
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
// Include Fonts
@import "fonts";
// Variables, Mixins
@import "imports/variables";
@import "imports/mixins";
// INSPINIA Theme Elements
@import "imports/typography";
@import "imports/navigation";
@import "imports/top_navigation";
@import "imports/buttons";
@import "imports/badges_labels";
@import "imports/elements";
@import "imports/sidebar";
@import "imports/base";
@import "imports/pages";
@import "imports/chat";
@import "imports/metismenu";
@import "imports/spinners";
// Landing page styles
// @import "imports/landing";
// RTL Support
@import "imports/rtl";
// For demo only - config box style
@import "imports/theme-config";
// INSPINIA Skins
@import "imports/skins";
@import "imports/md-skin";
// Media query style
@import "imports/media";
// Custom Variables
@import "variables";
// Custom style
// Navigation Updates
@import "navigation";
@import "fs-skin";
// Bootstrap Breadcrumbs Component
@import "imports/bs_nav_wizard";
// Your custom style to override base style
@import "imports/custom";

@import "latest.scss";

// Clear layout on print mode
@media print {
    nav.navbar-static-side {
        display: none;
    }
    body {
        overflow: visible !important;
    }
    #page-wrapper {
        margin: 0;
    }
}

@import "imports/print";


:last-child:where(h1 , h2, h3, h4, h5, h6, p) {
    margin-bottom: 0;
}

#page-wrapper {
    padding-inline: 0;
}


.footer {
    width: 100%;
}