$assets: 'https://assets.foursightonline.com/app';

@font-face {
	font-family: 'Museo-regular';
	src: url($assets +'/fonts/museo500-regular.eot');
	src: local('☺'), url($assets +'/fonts/museo500-regular.woff') format('woff'),
	 url($assets +'/fonts/museo500-regular.ttf') format('truetype'),
	 url($assets +'/fonts/museo500-regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Museo-100';
	src: url($assets +'/fonts/Museo-100.eot');
	src: local('☺'), url($assets +'/fonts/Museo-100.woff') format('woff'), url($assets +'/fonts/Museo-100.ttf') format('truetype'), url($assets +'/fonts/Museo-100.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Museo-300';
	src: url($assets +'/fonts/Museo-300.eot');
	src: local('☺'), url($assets +'/fonts/Museo-300.woff') format('woff'), url($assets +'/fonts/Museo-300.ttf') format('truetype'), url($assets +'/fonts/Museo-300.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Museo-300Italic';
	src: url($assets +'/fonts/Museo-300Italic.eot');
	src: local('☺'), url($assets +'/fonts/Museo-300Italic.woff') format('woff'), url($assets +'/fonts/Museo-300Italic.ttf') format('truetype'), url($assets +'/fonts/Museo-300Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Museo-700';
	src: url($assets +'/fonts/Museo-700.eot');
	src: local('☺'), url($assets +'/fonts/Museo-700.woff') format('woff'), url($assets +'/fonts/Museo-700.ttf') format('truetype'), url($assets +'/fonts/Museo-700.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Museo-700Italic';
	src: url($assets +'/fonts/Museo-700Italic.eot');
	src: local('☺'), url($assets +'/fonts/Museo-700Italic.woff') format('woff'), url($assets +'/fonts/Museo-700Italic.ttf') format('truetype'), url($assets +'/fonts/Museo-700Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'MuseoSans-300';
	src: url($assets +'/fonts/MuseoSans-300.eot');
	src: local('☺'), url($assets +'/fonts/MuseoSans-300.woff') format('woff'), url($assets +'/fonts/MuseoSans-300.ttf') format('truetype'), url($assets +'/fonts/MuseoSans-300.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'MuseoSansCondensed-300';
	src: url($assets +'/fonts/MuseoSans-300.eot');
	src: local('☺'), url($assets +'/fonts/MuseoSansCondensed-300.woff') format('woff'), url($assets +'/fonts/MuseoSansCondensed-300.woff') format('truetype'), url($assets +'/fonts/MuseoSansCondensed-300.woff') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'Marydale';
  src: url($assets +'/fonts/marydale/marydale.eot');
  src: local('☺'), url($assets +'/fonts/marydale/marydale.woff') format('woff'), url($assets +'/fonts/marydale/marydale.ttf') format('truetype'), url($assets +'/fonts/marydale/marydale.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
	font-family: 'Conv_museo-500-webfont';
	src: url($assets +'/fonts/museo-500-webfont.eot');
	src: local('☺'), url($assets +'/fonts/museo-500-webfont.woff') format('woff'), url($assets +'/fonts/museo-500-webfont.ttf') format('truetype'), url($assets +'/fonts/museo-500-webfont.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
$font-museo:'Conv_museo-500-webfont';

@import url('//fonts.googleapis.com/css?family=Bangers');
@import url('//fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400;1,700&display=swap');