/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');
@import url("https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css");
/* Import main Inspinia style */
@import "assets/styles/style";
// @import "~ng-pick-datetime/assets/style/picker.min.css";

.auth0-lock.auth0-lock .auth0-lock-header-logo {
    max-width: 220px;
    margin-bottom: 0!important
}

.auth0-lock.auth0-lock .auth0-lock-name {
    margin: -14px auto 0;
    font-size: 8px!important;
    max-width: 220px;
    text-align: right;
    color: #636363!important
}

.auth0-lock.auth0-lock .auth0-lock-header {
    height: 92px!important
}

.auth0-lock-html .auth0-lock-overlay {
    // display: none!important
}
