body{
	&.oh {
		overflow: hidden;
	}
     a{
      	color: $anchor-color;
     }
	&.fs-skin{
		// background-color: $fs-dark-grey;
		background-color: #fff;

		.chart-container, .below-chart, .below-chart-description {
		    box-shadow: 0px 0px 6px #cccaca;
		}
		.participants{
			box-shadow: 0px 0px 6px #cccaca;
			margin: 20px 0 20px 0;
		}
		.login-wrapper{
			small{
				color: $color-light-type;
		}
		a{
			small{
		 	 &:hover{
		    color: inherit;
		  }
		}
		}
		}
		.navbar-default{
			z-index: 99999;
     		background-color: $fs-sidenav-bg;
				height: 100%;
				position: fixed;
				.sidebar-collapse{
					height: 100%;
				}
     		span.trigger-mini-bar{
		    	position: absolute;
			    top: 10px;
			    right: 21px;
		    	a {
				    padding: 0 4px;
				    margin: 0;
				    background: transparent;
				    border: 2px solid $color-light-type;
				    color: $color-light-type;
				    border-radius: 6px;
				    font-size: 16px;
				}
		    }
			.above-side-nav {
			    padding: 40px 20px 10px 20px;
			    img {
				    max-width: 100%;
				    &.small{
				    	display: none;
				    }
				}
				small{
					max-width: 178px;
				}
			}
			ul#side-menu {
				/*top: 0;
				height: 100%;
				float: left;
				padding-top: 120px;
				padding-bottom: 175px;*/
				top:120px;

				& > .search-side-nav {
					height: 100%;
					padding-bottom: 12px;

					.scroll-bar {
						display: flex;
						flex-direction: column;

						&__spc {
							margin-top: auto;
						}

						// & > li:nth-last-of-type(5) {
						// 	margin-top: auto;
						// }

					}
					
				}

				li{

					ul.btmspc {
						
						li {

							&:last-child {
								padding-bottom: 15px;
							}
						}
					} 

					ul.nav-second-level {
						background: $fs-sidenav-bg;

						&.scroll-sub-nav {
							overflow-y: auto;
							height: 57px !important;
							overflow-x: hidden;
						}

						li {
							a,
							p{
								border-left: 4px solid transparent;
								padding-left: 36px;
								font-size: 12px;
							}

							&.active {
								border-left: 4px solid $fs-green;
								background-color: transparent;
							}

							&.cpf-pfmenu {

								&.active {
									background-color: #6D6E71;

									a {
										background-color: #6D6E71;
									}
								}

								.cn {
									padding: 0;
									list-style: none;
									
									li {
										border-left: 0;
										
										a {
											display: block;
											width: 100%;
											padding-left: 42px;
										}
										
										&.active {
											a {
												border-left: 4px solid $fs-green;
											}
										}
									}
								}
							}
						}
						li:last-child {
							margin-bottom: 0;
						}
					}

					// &:last-child {
					// 	padding-bottom: 12px;
					// }
				}
				ul.scroll-bar {
				    list-style: none;
				    padding: 0;
				    margin: 0;
				    overflow-y: auto;
				    overflow-x: hidden;
				    height: 100%;
				   	
					
					& > li {
						position: relative;
						display: block;
					   & > a {
							font-weight: 600;
							padding: 14px 20px 14px 25px;
							position: relative;
							display: block;
						}
					}
				}
			}
			.nav{
				li{
					&.active {
					    // background: $fs-active-light;
					    border: none;

						& > a {
							color: $fs-green;
							&[aria-expanded="false"] {
								color: #fff;
							}
						}

						&--clr {

							& > a {
								color: #fff !important;
							}

							.fa.arrow:before {
								content: "\f104";
							  }
						}
					}
					span{
						&.label.label-info.pull-right {
						    float: none !important;
						    background: transparent;
						    color: #71ba55;
						    font-size: 14px;
						}
					}
					a,
					p { 
						color: $color-light-type;
						&:hover, &:focus{
							// background: $fs-active-light;
							background: $fs-sidenav-hov-bg;
						}

						&.active {
							color: $fs-green;
						}

						&.nor-txt {
							margin: 0;
							font-weight: 600;
							padding: 7px 10px 7px 36px;
						}
					}
					&.nav-header {
						/*position: absolute;
						bottom: 0;
						height: 175px;*/
						width: 100%;
						background: $fs-sidenav-bg;
						ul.bottom-profile{
							height: auto !important;
						}
					    a{
					    	&.dropdown-toggle{
								span{
									background: #71ba55;
								    padding: 4px 8px;
								    color: $color-light-type;
								    border-radius: 3px;
								    position: relative;
								    top: 5px;
								}
					    	}
					    }
					    ul.nav.navbar-top-links {
						    padding: 0;
						    right: -220px;
						    background: #6e6e72;
						    border: none !important;
						    width: 200px;
						    bottom: -29px;
						    top: auto;
						    li.radio-text{
						    	label {
								    color: #d0d0d3;
								    font-size: 14px;
								    margin: 0;
								    display: block;
								    padding: 10px 10px;
								    font-weight: normal;
								    input {
									    width: 16px;
									}
								}
						    }
						    ul.top-levels{
									padding: 0px;
									width: 100%;
						    	li {
								    margin: 0;
								    padding: 0;

                    a{
                      padding: 8px 10px;
                      min-height: 20px;
											margin: 0px;
                    }
									}

                  .user-levels, .last-level {
								    padding: 4px 9px;
						      }

                  .before-user-levels{
                    margin-bottom: 10px;
                    border-bottom: 1px solid #fafafa;
                  }
						    }
								li{
									a{
										margin: 0px;
									}
								}
						}
					    span{
					    	&.profile-img{
					    		margin: 0 0 15px 0 !important;
    							display: block;
						    	img {
								    border-radius: 50%;
								    border-color: #f2f1f2;
								    border-width: 0px;
								    border-style: solid;
								    height: 60px;
								    width: 60px;
								}
						    }
							&.m-t-xs {
							    padding: 0;
							}
					    }
					}
				}
			}
		}
		.dashboard-header {
		    background: #f3f2f3;
		    .welcome-text{
		    	h2 {
				    display: inline-block;
				}
				fs-icon {
				    display: inline-block;
				    margin: 0 8px;
				}
				span.left-label a {
				    background: #6e6e72;
				    color: $color-light-type;
				    padding: 2px 8px;
				}
		    }
		}
		.page-heading{
			padding: 32px 20px 30px 20px;
		  	background: $color-neutral-light;

			@media only screen and (max-width: 1200px) {
				padding-top: 20px;
				padding-bottom: 10px;
			}
			  
		    h2{
		    	margin-top: 0px;
				text-overflow: ellipsis; 
				overflow: hidden; 
				white-space: nowrap;
				padding-bottom: 2px;
		    }

		    .breadcrumb{
		    	background: transparent !important;
		    }

		    .header-back{
		    	padding-top: 10px;
		    }
		}
		.gray-bg {
		    background-color: $color-light-type;
		}
		.ibox {
			border: 1px solid #ddd;
			border-radius: 4px;
		    border-color: #e2e2e2;
		}
	}
}

body.fs-skin.mini-navbar .navbar-default .above-side-nav img.small {
    display: block;
    opacity: 1;
    margin: 3px 0 6px 0;
    width: 35px;
}

.coach {

	.nav {

		@media only screen and (max-width:767px) {
			top: 70px !important;
		}
	}

	.above-side-nav {
		
		@media only screen and (max-width:767px) {
			padding-top: 17px!important;
			margin-top: 0 !important ;
		}
	
		.challenge-text {
			
			@media only screen and (max-width:767px) {
				display: none;
			}
		}
	}
}

body.fs-skin.mini-navbar .navbar-default .above-side-nav{
	padding: 23px 16px 10px 16px;
	margin-top: 20px;
}

body.fs-skin.mini-navbar .navbar-default .above-side-nav small {
	text-align: center;
}

body.fs-skin.mini-navbar .above-side-nav img {
	display: none;
}
body.fs-skin.mini-navbar .above-side-nav img.small {
	display: block;
}

.mCSB_inside > .mCSB_container{
	margin: 0px !important;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
	background-color: #71ba55 !important;
}



.right-label-text span.eq-clarifier {
    background-color: $clarify-color!important;
    @media print{
    	color:$color-light-type !important;
    	padding: 10px;
    }
}

.right-label-text span .icon-plus {
    width: 32px;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    color: #929394;
    display: inline-block;
    font: 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (max-width:992px){
    	width: 25px;
    }
}

.right-label-text .icon-plus:before {
    content: "\f067";
}

.right-label-text .eq-developer {
    background-color: #73BF38!important;
    @media print{
    	color:$color-light-type !important;
    	padding: 10px;
    }
}

.below-chart span {
    color: $color-light-type!important;
    padding: 10px;
}

.right-label-text .eq-ideator {
    background-color: #F78200!important;
    @media print{
    	color:$color-light-type !important;
    	padding: 10px;
    }
}
.right-label-text .eq-implementer {
    background-color: $implement-color!important;
    @media print{
    	color:$color-light-type !important;
    	padding: 10px;
    }
}


.profile-description span {
    font-weight: 700;
    color: #666!important;
    padding: 0;
    font-size: 1.2em;
}

@media (min-width:768px){
	.profile-section .chart-section .chart-main {
	    height: 350px !important;
	}
}

.page-heading{
  .col-lg-10{
    /*float: left;
    width: 75%;*/
    &.header-full{
      h2{
        padding-right: 0px;
      }

      @media only screen and (min-width:769px){
        width: 60%;
      }

      @media only screen and (max-width:768px){
        width: 100%;
        h2{
          padding-right: 50px;
        }
      }
    }
  }

  .header-back {
    width: 25%;
    float: left;
    text-align: right;
    padding-right: 0;
    padding-top: 30px;

    @media only screen and (max-width:768px){
      padding-top: 50px;
    }

    &.full-screen-option{
      width: 100%;
      padding-top: 10px;
      padding-right: 15px;

      @media only screen and (min-width:769px){
        width: 40%;
        padding-top: 30px;
      }

      @media only screen and (max-height:736px) and (orientation:landscape){
        padding-top: 0px;
        float: right;
        width:40%;
        margin-top: -20px;
      }
    }
  }
}

.nav li > a i {
  margin-right: 6px;
}

.gray-bg div:not(.all-toolcards) > .page-heading{
  	.col-lg-10{
	    float: left;
	    width: 75%;
	}
}

ul.scroll-bar.neg-margin{
	margin-left: -20px !important;
	margin-right: -20px !important;
}

.nav-header{
	.neg-margin{
		padding: 10px 20px 8px 25px;
	}
}

.mini-navbar ul.scroll-bar.neg-margin.mCS_no_scrollbar, .mini-navbar ul.scroll-bar.neg-margin.mCS_no_scrollbar ~ a.neg-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
body.fs-skin.mini-navbar .navbar-default .nav li.nav-header ul.nav.navbar-top-links{
	bottom:0px;
	left: 68px;
}
// for store 
.mini-navbar {

	.scroll-bar.mCustomScrollbar.bottom-profile.neg-margin._mCS_3 {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
}

/********fs-profile ********/
.below-chart span {
    display: inline-block;
}
 .below-chart span.right-label.right-label-text {
    padding: 0 !important;
}
.left-label{
   margin-bottom: 10px;
}
/***********footer *********/
@media screen and (max-width: 767px){
	.footer{
		text-align: center;
	}
}

body.fs-skin .navbar-default ul#side-menu ul.scroll-bar .mCSB_container > li{
	float: left;
	width: 100%;
}

.nav-item ul.dropdown-menu {
    display: none !important;
    position: relative;
    width: 100%;
}

.nav-item.open > ul.dropdown-menu {
    display: block !important;
}


.mobile-nav-trigger {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    padding: 15px;
    @media (max-width: 768px){
    	display: block;
    }
    span{
    	a {
		    margin: 0;
		}
    }
}

.page-heading{
  .col-lg-10 {
      float: left;
      width: 75%;
  }
}

.no-profile {
    background-image: url($assets+"/images/foursight-profile-types.svg")!important;
}

.gray-scale {
	filter: grayscale(1);
}

.navbar-default ul{
	.mindset-children{
		padding-left: 46px !important;
	}
}

.profile_types{
	cursor: pointer;
}
#toast-container{
	z-index: 2050999999999 !important;
}

.nav-slideshow {
	text-transform: capitalize;
}

.nav {

	&.nav-second-level {
		
		& li {

			& > a {
				padding-right: 20px;
	
				.arrow {
					color: $clrWhite;
				}

				&.active {

					.arrow {
						transform: rotate(-90deg);
					}
				}
			}
		} 
	}

	&.nav-third-level {
		
		& li {

			& > a {
				padding-left: 60px !important;
			}
		}
	}
}